import { clone, isString } from '@helpers/utils.js'
import { ref, computed, readonly } from 'vue'

const state = ref({ store: {} })
const onUpdateListeners = ref([])

export default function (key = null, data = {}) {
  if (key) {
    createStore(key, _cloneStore(data))
  }

  function getStore(key) {
    return computed(() => {
      if (state.value.store[key]) {
        return readonly(state.value.store[key])
      } else {
        return null
      }
    })
  }

  function generateId(key, ...ids) {
    return key.concat(`-${ids.join('-')}`)
  }

  function createStore(key, data) {
    const _data = _cloneStore(data)
    const updatedStore = {
      ...state.value.store,
      [key]: _data,
    }
    state.value.store = updatedStore
    onUpdateListeners.value.forEach((cb) => cb(key, { update: _data }))
  }

  function updateStore(key, data = null) {
    createStore(key, data)
  }

  // delete or refactor to be reusable function
  function updateStoreData(key, input, update) {
    const data = Object.assign({}, state.value.store[key], {
      [input]: update,
    })
    updateStore(key, data)
  }

  // delete or refactor to be reusable function
  function updateStoreArrayData(key, input, update) {
    const arrayData = state.value.store[key][input]
      ? state.value.store[key][input]
      : []
    updateStoreData(key, input, [...arrayData, update])
  }

  // delete or refactor to be reusable function
  function deleteStoreArrayData(key, input, cb) {
    const store = [...state.value.store[key][input]]

    store.forEach((data, index) => {
      if (cb(data)) {
        store.splice(index, 1)
      }
    })

    updateStoreData(key, input, store)
  }

  function _cloneStore(data) {
    if (isString(data)) {
      return clone(state.value.store[data])
    }

    return clone(data)
  }

  function addToStoreData(key, item) {
    const store = state.value.store[key]
    if (store?.data?.length) {
      const storeData = [...store.data]
      const index = storeData.findIndex((resource) => resource.id === item.id)

      index !== -1 ? storeData.splice(index, 1, item) : storeData.push(item)

      updateStore(key, { ...store, data: storeData })
    } else {
      createStore(key, {
        ...store,
        data: [item],
      })
    }
  }

  function refresh(key) {
    const storeCopy = _cloneStore(key)
    if (storeCopy) {
      storeCopy.refresh = true
      updateStore(key, storeCopy)
    }
  }

  function clearRefresh(key) {
    const storeCopy = _cloneStore(key)
    if (storeCopy) {
      storeCopy.refresh = false
      updateStore(key, storeCopy)
    }
  }

  function getKeys() {
    return Object.keys(state.value.store)
  }

  function addOnUpdateListener(callback) {
    onUpdateListeners.value.push(callback)
  }

  return {
    deleteStoreArrayData,
    updateStoreArrayData,
    addOnUpdateListener,
    updateStoreData,
    addToStoreData,
    clearRefresh,
    updateStore,
    createStore,
    generateId,
    getStore,
    refresh,
    getKeys,
  }
}
