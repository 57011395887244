import FontAwesomeIcon from '@plugins/fontawesome.js'
import messages from './js/locales/messages.js'
import directives from '@plugins/directives.js'
import App from '@views/layouts/ModioApp.vue'
import { createI18n } from 'vue-i18n'
import '@plugins/webInterface.js'
import { createApp } from 'vue'
import router from '@router'

// style imports
import 'highlight.js/styles/github-dark-dimmed.css'
import './sass/app.scss'
import './css/app.css'

export const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages,
})

const app = createApp(App)

app.use(router)
app.use(i18n)

// global error handler
/**
 * Autoloads components:
 * Base components
 */
const files = require.context('./components/Base', true, /\.vue$/i)
files
  .keys()
  .map((key) =>
    app.component(key.split('/').pop().split('.')[0], files(key).default)
  )

app.component('FontAwesomeIcon', FontAwesomeIcon)

directives(app)

app.mount('#app')
