import { useTheming } from '@composables'
import { computed, ref } from 'vue'

const { setDefaultThemeVars, resetGameThemeVars } = useTheming()

const globalModalState = ref(null)
const defaultTheme = ref(false)

export const globalModal = computed(() => globalModalState.value?.modal)
export const globalModalData = computed(() => globalModalState.value?.data)

export function setGlobalModal({ modal, data } = {}) {
  if (!modal) return
  globalModalState.value = { modal, data }
  _setDefaultTheme()
}

export function clearGlobalModal() {
  globalModalState.value = null
  _resetDefaultTheme()
}

export function useDefaultTheme() {
  defaultTheme.value = true
}

function _setDefaultTheme() {
  if (defaultTheme.value) {
    setDefaultThemeVars()
  }
}

function _resetDefaultTheme() {
  if (defaultTheme.value) {
    resetGameThemeVars()
    defaultTheme.value = false
  }
}

export default function useModal() {
  const state = ref(false)

  function showModal() {
    state.value = true
    _addBodyClass()
    _setDefaultTheme()
  }

  function hideModal(resetTheme = true) {
    state.value = false
    _removeBodyClass()
    if (resetTheme) {
      _resetDefaultTheme()
    }
  }

  const isModalOpen = computed(() => {
    return state.value
  })

  const triggerModal = () => {
    state.value === true ? hideModal() : showModal()
  }

  function _addBodyClass() {
    document.body.classList.add('tw-overflow-hidden')
  }

  function _removeBodyClass() {
    document.body.classList.remove('tw-overflow-hidden')
  }

  return {
    triggerModal,
    isModalOpen,
    showModal,
    hideModal,
  }
}
