<template>
  <div ref="mainWrap" class="tw-dark tw-text-dark-text">
    <component :is="widgetComponent"></component>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, computed } from 'vue'
import { useRoute, useBoundaries } from '@composables'
export default {
  setup() {
    const { routeMeta } = useRoute()
    const mainWrap = ref(null)
    const { setBoundaryVars } = useBoundaries()

    const widgetComponent = computed(() => routeMeta.value.widget?.component)

    onMounted(() => {
      nextTick(() => {
        setBoundaryVars('main', mainWrap.value.getBoundingClientRect())
      })
    })

    return {
      widgetComponent,
      mainWrap,
    }
  },
}
</script>
