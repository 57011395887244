import { isString } from '@helpers/utils.js'
import { computed, ref } from 'vue'

const state = ref({})
const submittedByState = ref({})
const searchUserName = ref(null)

export default function (id, param, submittedParam) {
  if (!id) {
    throw new Error('useSearch requires id')
  }

  if (param) {
    setSearch(param)
  }

  if (submittedParam) {
    setSubmittedBy(submittedParam)
  }

  function setSearch(value) {
    state.value[id] = isString(value) && value.trim()
  }

  function setSubmittedBy(value) {
    submittedByState.value[id] = value
  }

  const search = computed(() => state.value?.[id])

  const submittedBy = computed(() => submittedByState.value?.[id])

  function clearSearch() {
    delete state.value[id]
  }

  function _match(haystack, needle) {
    return haystack.toLowerCase().includes(needle.toLowerCase())
  }

  function inMemorySearch(data) {
    if (!search.value && !submittedBy.value) return data
    return data.filter((item) => {
      if (search.value && !_match(item.name, search.value)) return false
      if (
        submittedBy.value &&
        item.submitted_by?.id !== parseInt(submittedBy.value)
      )
        return false

      return true
    })
  }

  const getSearch = computed(() => {
    return {
      _q: search.value || null,
      submitted_by: submittedBy.value || null,
    }
  })

  return {
    searchUserName,
    setSubmittedBy,
    inMemorySearch,
    submittedBy,
    clearSearch,
    getSearch,
    setSearch,
    search,
  }
}
