import { isRef, computed } from 'vue'
import {
  generateSortQuery,
  numberFormatter,
  humanDateFormat,
  humanFilesize,
  isFunction,
  objectGet,
  timeSince,
  isString,
  proper,
  clone,
} from '@helpers/utils.js'

const FORMAT_SEPARATOR = '|'

export default function () {
  const transformation = {
    component: [],
    time: [(value) => timeSince(value)],
    date: [(value) => humanDateFormat(value)],
    link: [(value, cell) => _getLink(cell, value)],
    proper: [(value) => proper(value)],
    numberFormatter: [(value) => numberFormatter(value)],
    size: [(val) => humanFilesize(val, { exponent: 2 })],
    blank: [() => ''],
  }

  function getCells({ rowSchema, item }) {
    return computed(() => {
      if (!isRef(rowSchema) || !isRef(item)) {
        throw new TypeError()
      }
      return clone(rowSchema.value)
        .filter((cell) => {
          if (
            cell.condition &&
            isFunction(cell.condition) &&
            !cell.condition(item.value)
          ) {
            return false
          }
          return true
        })
        .map((cell) => _createCell(cell, item.value))
    })
  }

  function _createCell(cell, item) {
    if (isFunction(cell)) {
      cell = cell(item)
    }

    if (cell.active) {
      cell.active = _createCell(cell.active, item)
    }

    if (cell.group) {
      cell.group = cell.group
        .filter((groupCell) => {
          if (
            groupCell.condition &&
            isFunction(groupCell.condition) &&
            !groupCell.condition(item)
          ) {
            return false
          }
          return true
        })
        .map((groupCell) => _createCell(groupCell, item))
    }

    const value = cell.type === 'component' ? '' : getValue(cell, item)
    return { ...cell, value, item }
  }

  function _transform(value, format, cell, item) {
    let newValue = value
    if (isFunction(format)) {
      // pass the item value and cell
      // config to the function
      newValue = format(value, cell, item)
    } else if (isString(format)) {
      format.split(FORMAT_SEPARATOR).forEach((f) => {
        if (transformation[f] && transformation[f].length > 0) {
          transformation[f].forEach(
            (func) => (newValue = func(newValue, cell, item))
          )
        }
      })
    }

    return newValue
  }

  function getValue(cell, item) {
    const value = objectGet(item, cell.key)
    return _transform(value, cell.format, cell, item)
  }

  function _getLink(item, cell) {
    if (item && isFunction(cell.to)) {
      return cell.to(item)
    } else {
      throw new TypeError("'to' must be a function")
    }
  }

  function defaultSortQuery(schema) {
    const defaultSortKey = schema.defaultSort
    if (defaultSortKey) {
      const defaultSort = schema.config.find(
        (c) => c.key === schema.defaultSort
      )
      if (defaultSort) {
        return {
          _sort: generateSortQuery(defaultSort.sort?.direction, defaultSortKey),
        }
      }
    }

    return {}
  }

  return { defaultSortQuery, getCells }
}
