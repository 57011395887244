<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="showOverlay"
      class="tw-h-full tw-w-full tw-fixed tw-top-0 tw-left-0 tw-z-20 md:tw-z-21"
      @click="closeAllMenus()"
    >
      <div class="tw-opacity-20 tw-h-full tw-w-full tw-bg-black"></div>
    </div>
  </transition>
</template>

<script>
import { useNavMenus } from '@composables'
export default {
  setup() {
    const { showOverlay, closeAllMenus } = useNavMenus()
    return {
      closeAllMenus,
      showOverlay,
    }
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in;
}
.fade-enter-from,
.fade-leave-active {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}
</style>
