import Serializer, { extractCardImage } from './Serializer.js'
import { transformTags } from './cogsGameSerializer.js'

const transformations = [transformTags, extractCardImage(''), _UGCSingle]

const gameSerializer = new Serializer({
  transformations,
})

function _UGCSingle(game) {
  game.ugc_name = game.ugc_name || 'Mods'
  game.ugc_name_single = game.ugc_name.replace(/s$/, '')
  return game
}

export default gameSerializer
