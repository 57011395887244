module.exports = {
  BREAKPOINTS: {
    xxs: '380px',
    xs: '512px',
    sm: '660px',
    md: '768px',
    lg: '1000px',
    xl: '1340px',
    '2xl': '1680px',
  },
}
