import { isString, isObjEmpty, isObj, isFunction } from '@helpers/utils.js'
import { reactive } from 'vue'

export default function (validations = {}) {
  const { errors } = reactive({
    errors: {},
  })

  resetErrors()

  function validateAll(obj, skip = []) {
    resetErrors()
    Object.keys(validations)
      .filter((rule) => {
        return !skip.includes(rule)
      })
      .forEach((rule) => {
        validate(rule, obj[rule])
      })
  }

  function validate(field, input, customError, lazy) {
    _removeError(field)

    const rules = validations && validations[field]

    if (!rules) {
      return true
    }

    let errorCount = 0

    rules.forEach((validation) => {
      let err = null
      if (isObj(validation)) {
        if (validation.rule && isFunction(validation.rule)) {
          err = validation.rule({
            input,
            error: validation?.errorMessage,
          })
        } else {
          return
        }
      } else {
        err = validation({
          input,
          error: customError,
        })
      }

      const validationFuncName =
        validation.name !== 'temp' ? validation.name : validation.funcName
      if (!isString(err) || (input === '' && lazy)) {
        delete errors[field][validationFuncName]
      } else {
        errors[field][validationFuncName] = err
        errorCount++
      }
    })

    return errorCount === 0 ? true : false
  }

  function hasErrors(field) {
    if (field) {
      return !isObjEmpty(errors[field])
    }

    return Object.keys(errors).some(
      (key) => isObj(errors[key]) && !isObjEmpty(errors[key])
    )
  }

  function updateErrors(postErrors) {
    if (!isObj(postErrors)) {
      return
    }

    resetErrors()

    Object.keys(postErrors).forEach((field) => {
      const err = postErrors[field]

      if (isObj(err) && !isObjEmpty(err)) {
        const errorValidation = Object.keys(err)[0]
        const errObj = err[errorValidation]
        errors[field] = { [errorValidation.toLowerCase()]: errObj }
      } else if (isString(err)) {
        errors[field] = { [field]: err }

        // If error is on an array, map it to the base key, eg. urls.0 => urls
        const arrayMatch = field.match(/(.+)\.([0-9]+)/)
        if (arrayMatch) {
          errors[arrayMatch[1]] = { [field]: err }
        }
      }
    })
  }

  function resetErrors(error) {
    if (hasErrors()) {
      if (error) {
        _removeError(error)
      } else {
        Object.keys(errors).forEach((field) => {
          _removeError(field)
        })
      }
    }

    if (validations) {
      Object.keys(validations).forEach((field) => {
        _removeError(field)
      })
    }
  }

  function _removeError(field) {
    if (field && !isObjEmpty(errors[field])) {
      errors[field] = {}
    }
  }

  return {
    updateErrors,
    validateAll,
    resetErrors,
    hasErrors,
    validate,
    errors,
  }
}
