import { NEW_RESOURCE_ID } from '@config'
import {
  GAME_MOD_SUBMISSION_TOOLS_AND_WEBSITE,
  GAME_PRESENTATION_GRID,
  STATUS_NOT_ACCEPTED,
  CURATION_NONE,
} from '@config/options.js'

const gameDefaults = {
  id: NEW_RESOURCE_ID,
  name: '',
  status: STATUS_NOT_ACCEPTED,
  summary: '',
  profile_url: '',
  agreement: 0,
  email: '',
  plan: 1,
  support_url: '',
  other_urls: [],
  icon: {
    preview: undefined,
    file: null,
    dirty: false,
  },
  logo: {
    preview: undefined,
    file: null,
    dirty: false,
  },
  header: {
    preview: undefined,
    file: null,
    dirty: false,
  },
  ugc_name: '',
  presentation_option: GAME_PRESENTATION_GRID,
  maturity_options: 0,
  curation_option: CURATION_NONE,
  submission_option: GAME_MOD_SUBMISSION_TOOLS_AND_WEBSITE,
  instructions: '',
  tag_options: [],
  google_analytics: '',
  steam_ticket: '',
  gog_ticket: '',
  switch_app_id: '',
  oculus_rift_id: '',
  oculus_rift_secret: '',
  oculus_quest_id: '',
  oculus_quest_secret: '',
  direct_downloads: true,
  third_party_access: true,
  allow_ugc_sold: false,
  allow_ugc_donation: false,
  allow_ugc_trade: false,
  allow_ugc_supply: false,
  mod_share: 50,

  // new feature its own panel
  platform_pc: true,
  platform_xbox: true,
  platform_playstation: false,
  platform_nintendo: false,
}

export default gameDefaults
