<template>
  <!-- danger -->
  <div
    class="tw-content tw-view-text"
    :class="{ 'tw-whitespace-pre-line': !content.includes('<p>') }"
    v-html="stripLinks(content)"
  />
</template>

<script>
import { stripLinks } from '@helpers/formatContent.js'
import { watchEffect, nextTick } from 'vue'
import hljs from 'highlight.js/lib/common'
hljs.configure({ ignoreUnescapedHTML: true })

export default {
  props: {
    content: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    watchEffect(() => {
      if (props.content) {
        // Run on next tick after DOM has finished rendering
        nextTick(hljs.highlightAll)
      }
    })

    return {
      stripLinks,
    }
  },
}
</script>

<style>
@import url('/public/css/github-dark-dimmed.css');
</style>
