import { isObjEmpty, getQueryFromPath, clone } from '@helpers/utils.js'
import { BUTTON_ANIMATION_DURATION } from '@config'
import { LS_REDIRECT } from '@config/localstorage.js'
import { useInterrupted } from '@composables'
import { computed, ref, watch } from 'vue'
import { GAME_ID } from '@config/game.js'
import routes from '@router/routes.js'
import router from '@router'
import {
  GAME_BROWSE_ROUTE,
  FOUROHFOUR_ROUTE,
  GAME_ADMIN_ROUTE,
  HOME_ROUTE,
} from '@config/routeNames.js'

const redirectName = GAME_BROWSE_ROUTE

export default function (mockRouter) {
  const { interrupted } = useInterrupted()
  let route
  let $router

  if (mockRouter) {
    $router = mockRouter
    route = mockRouter.currentRoute
  } else {
    route = router.currentRoute
    $router = router
  }

  const active = ref(false)

  const routeParams = computed(() => route.value.params)
  const routeQuery = computed(() => route.value.query)
  const routePath = computed(() => route.value.path)
  const routeFullPath = computed(() => route.value.fullPath)
  const routeMeta = computed(() => route.value.meta)
  const routeError = computed(() => route.value.meta.error)
  const routeName = computed(() => route.value.name)
  const routeHash = computed(() => {
    const query = {}
    route.value.hash
      .replace('#', '')
      .split('&')
      .forEach((hash) => {
        const parts = hash.split('=')
        query[parts[0]] = parts[1]
      })

    return query
  })

  const hasParams = computed(() => !isObjEmpty(routeParams.value))

  function resolve(to) {
    try {
      return $router.resolve(to)
    } catch {
      console.error('could not resolve route')
    }
    return null
  }

  function routeHasParams(routeName) {
    return Object.keys(getRouteNameParams(routeName)).length !== 0
  }

  function getRouteNameParams(routeName) {
    if (routeName) {
      const route = $router.resolve({ name: routeName })
      const params = route && Object.values(route.params)
      const _routeParams = clone(routeParams.value)
      Object.entries(_routeParams).forEach(([key, value]) => {
        if (!params.includes(value)) {
          delete _routeParams[key]
        }
      })
      return _routeParams
    } else {
      return {}
    }
  }

  function getPathname() {
    const url = new URL(window.location.href)
    return url.pathname
  }

  function _isActive(value, name) {
    if (name === GAME_BROWSE_ROUTE && value === HOME_ROUTE) {
      return true
    }
    if (
      name === GAME_ADMIN_ROUTE &&
      value &&
      value.includes(GAME_ADMIN_ROUTE)
    ) {
      return true
    }
    if (value === name) {
      return true
    }
    return false
  }

  function push(to) {
    $router.push(to)
  }

  function replace(to) {
    $router.replace(to)
  }

  function watchActive(name) {
    watch(
      () => route.value.name,
      (value) => {
        active.value = _isActive(value, name)
      },
      {
        immediate: true,
      }
    )

    return computed(() => active.value)
  }

  function getGameId() {
    return computed(() => GAME_ID)
  }

  function getWidgetProps() {
    return routeMeta.value.widget?.props
  }

  function fourOhFour() {
    if (interrupted(routePath.value)) {
      return
    }
    push({
      name: FOUROHFOUR_ROUTE,
      // preserve current path and remove the first char to avoid the target URL starting with `//`
      params: { pathMatch: route.value.path.substring(1).split('/') },
    })
  }

  function getModId() {
    return computed(() => route.value?.params?.mod)
  }

  function getUserNameId() {
    return computed(() => route.value?.params?.user)
  }

  function getGuideId() {
    return computed(() => route.value?.params?.guide)
  }

  function getBreadCrumbMeta(routeName) {
    const route = routes.find((r) => r.name === routeName)
    return route?.meta?.breadcrumb || null
  }

  function redirectToHome() {
    if (route.value.name === redirectName) {
      location.reload()
    } else {
      push({ name: redirectName })
    }
  }

  function redirectOnLogin() {
    const redirectURL = localStorage.getItem(LS_REDIRECT)
    setTimeout(() => {
      if (redirectURL) {
        const query = getQueryFromPath(redirectURL)
        localStorage.setItem(LS_REDIRECT, '')
        push({ path: redirectURL, query })
      } else {
        window.location = '/g'
      }
    }, BUTTON_ANIMATION_DURATION)
  }

  return {
    getRouteNameParams,
    getBreadCrumbMeta,
    redirectOnLogin,
    redirectToHome,
    routeHasParams,
    getWidgetProps,
    routeFullPath,
    getUserNameId,
    routeParams,
    watchActive,
    getPathname,
    fourOhFour,
    routeQuery,
    getGuideId,
    routeError,
    hasParams,
    routeHash,
    routeName,
    routeMeta,
    routePath,
    getGameId,
    getModId,
    resolve,
    replace,
    route,
    push,
  }
}
