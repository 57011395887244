// route themes
export const THEME_HOME = 'theme-home'
export const THEME_ADMIN = 'theme-admin'
export const THEME_GAME = 'theme-game'
export const THEME_DEFAULT = 'theme-default'

// default theme colors
export const DEFAULT_PRIMARY = '#07C1D8'
export const DEFAULT_DARK = '#212945'
export const DEFAULT_LIGHT = '#EAEBF2'
export const DEFAULT_SUCCESS = '#7EEF8C'
export const DEFAULT_WARNING = '#FEF376'
export const DEFAULT_DANGER = '#DB5355'

// default home colors
export const HOME_DARK = '#212945'

// default admin colors
export const ADMIN_PRIMARY = '#448AD5'
export const ADMIN_DARK = '#43436B'

// default theme colors
export const LIGHT_TEXT = '#0E101A'
export const DARK_TEXT = '#FFFFFF'
