import { computed, ref, watch, nextTick } from 'vue'
import { BUTTON_ANIMATION_DURATION } from '@config'

export default function ({ loading = null, error = null } = {}) {
  const statusType = {
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error',
  }

  const state = ref(null)

  const isLoading = computed(() => {
    return state.value === statusType.LOADING
  })

  const isSuccess = computed(() => {
    return state.value === statusType.SUCCESS
  })

  const isError = computed(() => {
    return state.value === statusType.ERROR
  })

  const status = computed(() => {
    return state.value
  })

  const isAnimating = computed(() => {
    return status.value
  })

  function watchStatusAnimation(statusProp) {
    watch(
      statusProp,
      (value) => {
        if (value) updateStatus(value)
      },
      { immediate: true }
    )

    return {
      isAnimating,
      isLoading,
      isSuccess,
      isError,
    }
  }

  function updateStatus(update) {
    if (!Object.values(statusType).includes(update)) {
      throw new TypeError()
    }

    nextTick(() => {
      state.value = update
    })

    if (update === statusType.SUCCESS || update === statusType.ERROR) {
      setTimeout(clearStatus, BUTTON_ANIMATION_DURATION)
    }
  }

  function clearStatus() {
    state.value = null
  }

  function watchLoading(isLoading) {
    watch(isLoading, (value) => {
      if (value) {
        updateStatus(statusType.LOADING)
      } else {
        clearStatus()
      }
    })
  }

  function watchError(error) {
    watch(error, (value) => {
      if (value) {
        updateStatus(statusType.ERROR)
      } else {
        clearStatus()
      }
    })
  }

  if (loading) {
    watchLoading(loading)
  }

  if (error) {
    watchError(error)
  }

  return {
    watchStatusAnimation,
    watchLoading,
    updateStatus,
    clearStatus,
    watchError,
    statusType,
    status,
  }
}
