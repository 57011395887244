import { hasDynamicBreadcrumbName, clone } from '@helpers/utils.js'
import { USER_PARAM, UGC_PARAM } from '@config/routeNames.js'
import { useStoreWatcher, useRoute } from '@composables'
import { computed, ref } from 'vue'

const state = ref([])

export default function () {
  const { registerResourceWatch } = useStoreWatcher()
  const { routeParams, routeMeta } = useRoute()

  const breadCrumbMeta = routeMeta.value?.breadcrumb

  const breadCrumbLoading = computed(() => {
    return breadCrumbMeta && state.value.length === 0
  })

  const breadCrumbParams = computed(() =>
    state.value.length ? state.value : null
  )
  const breadCrumb = computed(() => {
    const bag = []
    if (breadCrumbMeta) {
      for (const [key, crumb] of Object.entries(breadCrumbMeta)) {
        if (key !== 'head' && key !== 'tail') {
          bag.push(crumb)
        }
      }
    }

    if (breadCrumbMeta?.head) bag.unshift(breadCrumbMeta.head)

    if (breadCrumbMeta?.tail) bag.push(breadCrumbMeta.tail)

    return bag
  })

  function handleBreadcrumbs() {
    clearBreadCrumb()
    if (!breadCrumb.value.some((b) => hasDynamicBreadcrumbName(b.label))) {
      state.value = breadCrumb.value
    } else {
      registerResourceWatch((results) => {
        _setBreadcrumbs(results)
      })
    }
  }

  function _setBreadcrumbs(results) {
    const newBreadcrumb = clone(breadCrumb.value)
    newBreadcrumb.forEach((b) => {
      if (hasDynamicBreadcrumbName(b.label)) {
        const resource = b.label.split(':')[1]
        if (results[resource]) {
          if (b.label === UGC_PARAM) {
            b.label = results[resource].ugc_name
          } else {
            b.label =
              b.label === USER_PARAM
                ? results[resource].username
                : results[resource].name
          }
          if (b.to?.params) {
            b.to.params = Object.fromEntries(
              b.to.params.map((p) => [p, routeParams.value[p]])
            )
          }
        }
      }
    })
    state.value = newBreadcrumb
  }

  function clearBreadCrumb() {
    state.value = []
  }

  return {
    handleBreadcrumbs,
    breadCrumbLoading,
    breadCrumbParams,
    clearBreadCrumb,
    breadCrumb,
  }
}
