import { i18n } from '@/src/main.js'

const interfaceName = 'modioWeb'
const DEBUG = true

function _debugLog(fnName) {
  if (DEBUG) {
    console.log('Calling', fnName)

    if (!global[interfaceName]) {
      console.warn('Missing global interface:', interfaceName)
    } else if (!global[interfaceName][fnName]) {
      console.warn('Missing implementation for:', fnName)
    }
  }

  function log() {
    if (DEBUG) console.log(`${fnName}:`, ...arguments)
  }

  function err() {
    if (DEBUG) console.error(`${fnName}:`, ...arguments)
  }

  return {
    err,
    log,
  }
}

/**
 * Set the language to use on the web app
 * @param {'en' | 'fr' | 'de' | 'it' | 'es' | 'pl' | 'ja' | 'ko' | 'ru' | 'zh-CN' | 'zh-TW'} lang The language to display
 * @returns {void}
 */
function setLanguage(lang) {
  const { err } = _debugLog('setLanguage')

  try {
    i18n.global.locale.value = lang
    return i18n.global.locale.value
  } catch (error) {
    err(error)
  }
}

const webInterface = {
  setLanguage,
}

global[interfaceName] = webInterface

export default webInterface
