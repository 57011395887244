<template>
  <div class="tw-w-full tw-space-y-2 sm:tw-space-y-4">
    <div class="tw-flex tw-flex-wrap tw-justify-end tw--mr-4 tw--mb-2">
      <slot name="search" />

      <slot name="filters" />

      <slot name="sort" />
    </div>

    <div
      class="tw-flex tw-flex-col-reverse sm:tw-flex-row tw-justify-between sm:tw-space-x-4"
    >
      <div class="tw-mt-2 xs:tw-mt-0 tw-pb-1.5">
        <slot name="totals" />
      </div>

      <div
        class="tw-mt-4 xs:tw-mt-0 tw-pb-1.5 tw-flex-1 tw-flex tw-justify-end empty:tw-hidden"
      >
        <slot name="tags" />
      </div>
    </div>
  </div>
</template>
