<template>
  <div
    class="tw-flex tw-flex-col tw-w-full md:tw-flex-row md:tw-space-x-5 lg:tw-space-x-10 md:tw-space-y-0"
    :class="colYClass"
  >
    <div
      class="tw-flex tw-flex-col tw-w-full"
      :class="[
        firstCol,
        colYClass,
        { 'md:tw-w-full': autoExpand && !$slots.second },
      ]"
    >
      <slot name="first" />
      <slot name="first-middle" />
      <slot name="first-bottom" />
    </div>
    <div
      class="tw-flex tw-flex-col tw-w-full"
      :class="[
        secondCol,
        colYClass,
        { 'tw-hidden': autoExpand && !$slots.second },
      ]"
    >
      <slot name="second" />
      <slot name="second-bottom" />
    </div>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue'
export default {
  props: {
    reverseCols: {
      type: Boolean,
      default: false,
    },
    colSpacingY: {
      type: String,
      default: '4',
      validator: function (value) {
        return ['0', '1', '2', '3', '4'].indexOf(value) !== -1
      },
    },
    autoExpand: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { reverseCols, colSpacingY } = toRefs(props)
    const firstCol = computed(() =>
      reverseCols.value ? 'md:tw-w-1/3' : 'md:tw-w-2/3'
    )

    const secondCol = computed(() =>
      reverseCols.value ? 'md:tw-w-2/3' : 'md:tw-w-1/3'
    )

    const colYClass = computed(() => {
      if (colSpacingY.value === '4') {
        return 'tw-space-y-4'
      } else if (colSpacingY.value === '3') {
        return 'tw-space-y-3'
      } else if (colSpacingY.value === '2') {
        return 'tw-space-y-2'
      } else if (colSpacingY.value === '0') {
        return ''
      } else {
        return 'tw-space-y-1'
      }
    })

    return {
      colYClass,
      secondCol,
      firstCol,
    }
  },
}
</script>
