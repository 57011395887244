import { isNumber, clone } from '@helpers/utils.js'
import { computed, ref } from 'vue'
import {
  PAGINATION_LIMIT_OPTIONS,
  PAGINATION_DEFAULT_PAGE,
  PAGINATION_DEFAULT_TOTAL,
  PAGINATION_DEFAULT_LIMIT,
} from '@config/options.js'
const state = ref({})

export default function (
  id,
  { offset = null, page = null, limit = null } = {}
) {
  if (!id) {
    throw new Error('usePagination requires id')
  }

  if (!state.value[id]) {
    resetPagination()
  }

  if (offset && !page) {
    page = offset / limit + 1
    setPagination({ page, limit })
  } else if (page) {
    setPagination({ page, limit })
  } else if (limit) {
    setPagination({ limit })
  }

  const totalPages = computed(() => {
    return Math.ceil(state.value[id].total / state.value[id].limit)
  })

  const pagination = computed(() => {
    if (!state.value[id]) {
      setPagination({ page, limit })
    }
    return state.value[id]
  })

  const getPagination = computed(() => {
    return {
      _limit: state.value[id].limit,
      _offset: calculateOffset(),
    }
  })

  const getLimit = computed(() => getPagination.value._limit)

  function calculateOffset() {
    const page =
      state.value[id] && state.value[id].page === 0
        ? state.value[id].page
        : state.value[id].page - 1

    return state.value[id].limit * page
  }

  function next() {
    const canNext = !(totalPages.value === state.value[id].page)
    if (!canNext) return false

    setPagination({ page: state.value[id].page + 1 })
    return true
  }

  function prev() {
    const canPrev = !(totalPages.value === 0) && state.value[id].page !== 1

    if (!canPrev) return false

    setPagination({ page: state.value[id].page - 1 })
    return true
  }

  function inMemoryPagination(data) {
    setPagination({ total: data.length })
    return _inMemoryPaginate(data)
  }

  function resetPagination() {
    state.value[id] = {
      page: PAGINATION_DEFAULT_PAGE,
      total: PAGINATION_DEFAULT_TOTAL,
      limit: PAGINATION_DEFAULT_LIMIT,
    }
  }

  function setPagination({ page, limit, total } = {}) {
    state.value[id] = _createPagination({
      page: page ? parseInt(page) : null,
      limit: limit ? parseInt(limit) : null,
      total: isNumber(total) ? parseInt(total) : null,
    })
  }

  function _inMemoryPaginate(data) {
    let resultsCopy = clone(data)
    const offset = calculateOffset()
    return resultsCopy.slice(calculateOffset(), state.value[id].limit + offset)
  }

  function _createPagination({ page = null, limit = null, total = null } = {}) {
    page = page
      ? page
      : state.value[id] && state.value[id].page
      ? state.value[id].page
      : PAGINATION_DEFAULT_PAGE

    total =
      total || total === 0
        ? total
        : state.value[id] && state.value[id].total
        ? state.value[id].total
        : PAGINATION_DEFAULT_TOTAL

    limit = limit
      ? limit
      : state.value[id] && state.value[id].limit
      ? state.value[id].limit
      : PAGINATION_DEFAULT_LIMIT

    const newPageCount = Math.ceil(total / limit)

    if (newPageCount < page && total !== 0) {
      page = newPageCount
    }

    return {
      total,
      limit,
      page,
    }
  }

  return {
    limitOptions: PAGINATION_LIMIT_OPTIONS,
    inMemoryPagination,
    calculateOffset,
    resetPagination,
    setPagination,
    getPagination,
    pagination,
    totalPages,
    getLimit,
    next,
    prev,
  }
}
