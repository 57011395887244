import { LS_REDIRECT } from '@config/localstorage.js'
import { useRoute } from '@composables'
import { computed, ref } from 'vue'
import {
  GUIDE_ADMIN_EDIT_ROUTE,
  GUIDE_BROWSE_ROUTE,
  GAME_BROWSE_ROUTE,
  GAME_ADMIN_ROUTE,
  MOD_BROWSE_ROUTE,
  MOD_ADMIN_ROUTE,
  LOGIN_ROUTE,
} from '@config/routeNames'

const GAME_404_ERROR = 'The requested game id could not be found.'
const MOD_404_ERROR = 'The requested mod id could not be found.'
const GUIDE_404_ERROR = 'The requested guide id could not be found.'

const MOD_ERRORREF_HIDDEN = 15024
const GUIDE_ERRORREF_NO_PERMISSION = 14026
export const GAME_ERRORREF_NO_EDIT = 14002
export const MOD_ERRORREF_NO_EDIT = 15013
export const MOD_ERRORREF_DELETED = 15023
export const GUIDE_ERRORREF_DELETED = 19043

const suggestionComponentState = ref(null)
const showBannerState = ref(false)
const suggestionRouteNameState = ref('')
const suggestionErrorState = ref(null)
const suggestionRequestedIdState = ref(null)

export default function () {
  const suggestionComponent = computed(() => suggestionComponentState.value)
  const showBanner = computed(() => showBannerState.value)
  const suggestionRouteName = computed(() => suggestionRouteNameState.value)
  const suggestionError = computed(() => suggestionErrorState.value)
  const suggestionRequestedId = computed(() => suggestionRequestedIdState.value)

  function setShowBanner(bool) {
    showBannerState.value = bool
  }

  function clearSuggestions() {
    setShowBanner(false)
    suggestionRouteNameState.value = ''
    suggestionComponentState.value = null
    suggestionErrorState.value = null
    suggestionRequestedIdState.value = null
  }

  function handleSuggestion(error) {
    const { routeName, routeParams, routePath, replace } = useRoute()
    clearSuggestions()

    if (error.status === 500) return

    suggestionErrorState.value = error
    if (!error || error.status === 403) {
      suggestionComponentState.value = 'Login'
      suggestionRouteNameState.value = LOGIN_ROUTE
      localStorage.setItem(LS_REDIRECT, routePath.value)
    } else {
      setShowBanner(true)
      if (error.message === GAME_404_ERROR) {
        if (routeParams.value.game) {
          suggestionRequestedIdState.value = routeParams.value.game
        }
        suggestionComponentState.value = 'GameError'
        suggestionRouteNameState.value = GAME_BROWSE_ROUTE
      } else if (
        error.message === MOD_404_ERROR ||
        error.errorRef === MOD_ERRORREF_HIDDEN ||
        error.errorRef === MOD_ERRORREF_DELETED ||
        (routeName.value.includes(MOD_ADMIN_ROUTE) && error.status === 403) ||
        (routeName.value.includes(GAME_ADMIN_ROUTE) && error.status === 403)
      ) {
        if (routeParams.value.mod) {
          suggestionRequestedIdState.value = routeParams.value.mod
        }
        suggestionComponentState.value = 'ModError'
        suggestionRouteNameState.value = MOD_BROWSE_ROUTE
      } else if (
        error.message === GUIDE_404_ERROR ||
        error.errorRef === GUIDE_ERRORREF_NO_PERMISSION ||
        error.errorRef === GUIDE_ERRORREF_DELETED ||
        (routeName.value.includes(GUIDE_ADMIN_EDIT_ROUTE) &&
          error.status === 403)
      ) {
        if (routeParams.value.guide) {
          suggestionRequestedIdState.value = routeParams.value.guide
        }
        suggestionComponentState.value = 'GuideError'
        suggestionRouteNameState.value = GUIDE_BROWSE_ROUTE
      } else {
        suggestionRouteNameState.value = GAME_BROWSE_ROUTE
      }
    }

    replace({ name: suggestionRouteNameState.value })
  }

  return {
    suggestionRequestedId,
    suggestionRouteName,
    suggestionComponent,
    clearSuggestions,
    handleSuggestion,
    suggestionError,
    setShowBanner,
    showBanner,
  }
}
