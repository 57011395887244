import { PUBLIC_BASE_URL } from '@config'
import { createClient } from '@services'
import qs from 'qs'

const { post } = createClient()

export async function submitReportRequest(report) {
  const { data } = await post(`${PUBLIC_BASE_URL}/report`, qs.stringify(report))
  return data
}
