import { requestAll } from '@helpers/requestAll.js'
import { PUBLIC_API_KEY } from '@config/game.js'
import { queryString } from '@helpers/utils.js'
import { PUBLIC_BASE_URL } from '@config'
import { createClient } from '@services'

const { get } = createClient()

/* API request to get files */
export function getAllFilesRequest(gameId, modId, query) {
  query.api_key = PUBLIC_API_KEY
  return requestAll(
    `${PUBLIC_BASE_URL}/games/${gameId}/mods/${modId}/files`,
    query
  )
}

/* API request to get a file */
export async function getFileRequest(gameId, modId, fileId) {
  const query = { api_key: PUBLIC_API_KEY }
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/${gameId}/mods/${modId}/files/${fileId}${queryString(
      query
    )}`
  )
  return data
}
