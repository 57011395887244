import { PUBLIC_API_KEY } from '@config/game.js'
import { queryString } from '@helpers/utils.js'
import { PUBLIC_BASE_URL } from '@config'
import { createClient } from '@services'

const { get } = createClient()

/* API request get game details from public API */
export async function getPublicGameRequest(gameId) {
  const query = { api_key: PUBLIC_API_KEY }
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/${gameId}${queryString(query)}`
  )
  return data
}
