<template>
  <div v-if="show" class="tw-h-40">
    <div class="loading-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
