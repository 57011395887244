import modSerializer from '@serializers/modSerializer.js'
import { KEYS_MODS } from '@config/storeKeys.js'
import { isArray } from '@helpers/utils.js'
import { store } from '@stores'
import { computed } from 'vue'

const {
  addToStoreData,
  createStore,
  updateStore,
  generateId,
  getStore,
  refresh,
} = store()

export default function () {
  function getMods(gameNameId) {
    return getStore(generateId(KEYS_MODS, gameNameId))
  }

  function getModByNameId(gameNameId, modNameId) {
    if (!gameNameId || !modNameId) {
      throw new TypeError()
    }

    const mods = getStore(generateId(KEYS_MODS, gameNameId))

    return computed(() =>
      mods.value?.data
        ? mods.value.data.find((mod) => mod.name_id === modNameId)
        : null
    )
  }

  function getModById(gameNameId, modId) {
    const mods = getStore(generateId(KEYS_MODS, gameNameId))

    return computed(() =>
      mods.value?.data ? mods.value.data.find((mod) => mod.id == modId) : null
    )
  }

  function addMods(gameNameId, mods, query) {
    createStore(generateId(KEYS_MODS, gameNameId), {
      ...modSerializer.transform(mods),
      query,
    })
  }

  function removeMods(gameNameId) {
    updateStore(generateId(KEYS_MODS, gameNameId))
  }

  function addMod(gameNameId, mod) {
    const _mod = modSerializer.transform(mod)

    addToStoreData(generateId(KEYS_MODS, gameNameId), _mod)

    const mods = getMods(gameNameId)
    if (_requireModsRefresh(mods.value, _mod)) {
      refreshMods(gameNameId)
    }

    return _mod
  }

  function refreshMods(gameNameId) {
    refresh(generateId(KEYS_MODS, gameNameId))
  }

  function _requireModsRefresh(mods, mod) {
    return (
      mods?.data &&
      isArray(mods.data) &&
      mods.data.length === 1 &&
      mods.data[0].id === mod.id
    )
  }

  function requireModsRefresh(gameNameId) {
    const mods = getMods(gameNameId)
    return (mods.value && mods.value.refresh) || !mods.value
  }

  return {
    requireModsRefresh,
    getModByNameId,
    refreshMods,
    getModById,
    removeMods,
    addMods,
    getMods,
    addMod,
  }
}
