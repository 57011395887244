import { isFunction, apiErrorsToArray } from '@helpers/utils.js'
import errorMessages from '@errors/ErrorMessages.js'
import { useToast, useRoute } from '@composables'
import { DEFAULT_ERROR_MESSAGE } from '@config'

export default function () {
  const { addToast, defaultErrorToast } = useToast()
  const { routeError, push, routeParams } = useRoute()

  function handleError({ error, errorState, disableRouteHandling = false }) {
    let errorText
    if (error.errors) {
      errorState.value = error.errors
      errorText = apiErrorsToArray(error.errors)
    } else if (error.message) {
      errorState.value = errorMessages.getErrorMessage(error.message)
      errorText = errorState.value
    } else {
      errorState.value = DEFAULT_ERROR_MESSAGE
      errorText = DEFAULT_ERROR_MESSAGE
    }

    if (!disableRouteHandling) {
      const config = _getErrorConfig(error)
      if (config) _handle(config, error)
    }

    const errorMessage = `code: ${error.code} | errorRef: ${error.errorRef} | errorMessage: ${errorText} `
    console.error(errorMessage)
  }

  function _getErrorConfig(error) {
    if (!routeError.value || !error) return null
    return routeError.value.find(
      (c) =>
        _matchMethod(c, error) &&
        _matchStatus(c, error) &&
        _matchErrorRef(c, error)
    )
  }

  function _handle(config, error) {
    if (!config) return
    if (config.toast) {
      if (isFunction(config.toast)) {
        addToast({ isError: true, ...config.toast(error) })
      } else {
        defaultErrorToast()
      }
    }

    if (config.redirect) {
      push({ name: config.redirect })
    } else if (config.action) {
      _handleAction(config.action, error)
    }
  }

  function _handleAction(cb, error) {
    cb(routeParams.value, error)
  }

  function _matchStatus(config, error) {
    if (config.status === '*') return true

    return config.status === error.code
  }

  function _matchMethod(config, error) {
    if (config.method === '*') return true
    return config.method === error.method
  }

  function _matchErrorRef(config, error) {
    if (config.errorRef === '*') return true
    return config.errorRef === error.errorRef
  }
  return {
    handleError,
  }
}
