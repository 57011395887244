// login=false means you can only link this account in your control panel this is important to minimize number of login methods offered to the essential ones
export const LOGIN_METHODS = [
  {
    label: 'Steam',
    icon: ['fab', 'steam-symbol'],
    background: 'tw-bg-steam',
    type: 'steam',
    url: '/steam/login',
    connectUrl: '/steam/connect',
    login: true,
  },
  {
    label: 'Xbox Live',
    icon: ['fab', 'xbox'],
    background: 'tw-bg-xboxlive',
    type: 'xboxlive',
    url: '/xboxlive/login',
    connectUrl: '/xboxlive/connect',
    login: true,
  },
  {
    label: 'PlayStation™Network',
    icon: ['fab', 'playstation'],
    background: 'tw-bg-playstation',
    type: 'psn',
    url: '/psn/login',
    connectUrl: '/psn/connect',
    login: true,
  },
  {
    label: 'Email',
    icon: 'envelope',
    background: 'tw-bg-email',
    type: 'email',
    url: '',
    login: true,
  },
  {
    label: 'Google',
    icon: ['fab', 'google'],
    background: 'tw-bg-google',
    type: 'google',
    url: '/google/login',
    connectUrl: '/google/connect',
    login: true,
  },
  {
    label: 'Discord',
    icon: ['fab', 'discord'],
    background: 'tw-bg-discord',
    type: 'discord',
    url: '/discord/login',
    connectUrl: '/discord/connect',
    login: true,
  },
  {
    label: 'Itch.io',
    icon: ['fab', 'itch-io'],
    background: 'tw-bg-itchio',
    type: 'itchio',
    url: '/itchio/login',
    connectUrl: '/itchio/connect',
    login: false,
  },
]
