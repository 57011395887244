import { API_RESULT_LIMIT } from '@config'
import { createClient } from '@services'
import qs from 'qs'

const { get } = createClient()

// Function will not scale well
export async function requestAll(url, queryObj) {
  if (queryObj) {
    url = `${url}?${qs.stringify({ ...queryObj, _limit: API_RESULT_LIMIT })}`
  }

  let { data } = await get(url)

  const offset = []
  let t = data.result_total
  while (t > 0) {
    if (t > API_RESULT_LIMIT) {
      offset.push(API_RESULT_LIMIT * (offset.length + 1))
      t = t - API_RESULT_LIMIT
    } else {
      t = 0
    }
  }
  const query = url.includes('?') ? '&_offset=' : '?_offset='

  const result = await Promise.all(
    offset.map((offs) => get(`${url}${query}${offs}`))
  )

  result.forEach((res) => {
    data = mergeApiResultData(data, res.data)
  })

  return data
}

export function mergeApiResultData(target, source) {
  return {
    ...target,
    data: [...target.data, ...source.data],
    result_count: target.result_count + source.result_count,
  }
}
