import { LS_REDIRECT, LS_CONNECTING } from '@config/localstorage.js'
import { subscriptionStore, ratingStore, authStore } from '@stores'
import { parseCookie, asyncSleep } from '@helpers/utils.js'
import * as sdkInterface from '@plugins/sdkInterface.js'
import { useSuggestions, useToast } from '@composables'
import { PUBLIC_API_KEY } from '@config/game.js'
import { LOGIN_METHODS } from '@config/auth.js'
import { i18n } from '@/src/main.js'
import qs from 'qs'
import {
  getMySubscriptionsRequest,
  getAllUserRatings,
  getUserRequest,
  createClient,
} from '@services'
import {
  REPLICATION_DELAY_MS,
  PUBLIC_BASE_URL,
  LOGOUT_ENDPOINT,
  AUTH_BASE_URL,
} from '@config'

const { clearUser, setToken, setUser, isLoggedIn } = authStore()
const { setMySubscriptions } = subscriptionStore()
const { setUserRatings } = ratingStore()
const { post, get } = createClient()

/* API request to update local storage data */
export async function updateLocalStorage() {
  const response = await Promise.allSettled([
    getUserRequest(),
    getMySubscriptionsRequest(),
    getAllUserRatings(),
  ])

  if (response.some((promise) => promise.reason?.code === 401)) {
    // unauthorised
    clearUser()
    const t = i18n.global.t
    useToast().addToast({
      title: t('login_fail'),
      isError: true,
      text: t('login_fail_message'),
    })
    throw new Error()
  }

  if (response[1].status === 'fulfilled')
    setMySubscriptions(response[1].value.data)
  if (response[2].status === 'fulfilled') setUserRatings(response[2].value.data)

  // set user required to HERE to kept login modal visible
  // until local storage data requests complete
  if (response[0].status === 'fulfilled') {
    setUser(response[0].value)
  }
}

/* API request to request code */
export async function requestCodeRequest({ email } = {}) {
  const query = { api_key: PUBLIC_API_KEY, email }
  const { data } = await post(
    `${PUBLIC_BASE_URL}/oauth/emailrequest`,
    qs.stringify(query)
  )
  return data
}

/* API request to login using code */
export async function loginRequest({ code } = {}) {
  const query = { api_key: PUBLIC_API_KEY, security_code: code }
  const {
    data: { access_token },
  } = await post(`${PUBLIC_BASE_URL}/oauth/emailexchange`, qs.stringify(query))

  await _boot({ access_token })
}

/* API request to logout */
export async function logoutRequest() {
  if (!isLoggedIn.value) return

  try {
    await post(LOGOUT_ENDPOINT)
  } catch (err) {
    console.error(err)
  } finally {
    clearUser()
  }
}

/* API request to retrieve oauth login redirect url */
export async function oAuthUrlRequest(service, connect, storeRedirect = true) {
  const url = _getUrl(service, connect)
  if (!url) return

  if (
    window.location.href &&
    storeRedirect &&
    !localStorage.getItem(LS_REDIRECT)
  ) {
    localStorage.setItem(
      LS_REDIRECT,
      window.location.pathname + window.location.search
    )
  }

  if (connect) {
    localStorage.setItem(LS_CONNECTING, 'true')
  }

  const { data } = await get(`${AUTH_BASE_URL}${url}`, {
    withCredentials: true,
  })

  if (data.url) {
    return data.url
  }
}

/* API request code login user with oauth */
export async function oAuthLoginRequest(service, path) {
  try {
    const url = _getUrl(service)
    if (!url || !service || !path) {
      throw new Error()
    }

    const query = path.replace(/[^?]*/, '')

    const {
      data: { user, access_token },
    } = await get(`${AUTH_BASE_URL}${url}${query}`, {
      withCredentials: true,
    })

    if (user && access_token) {
      return await _boot({ user, access_token })
    } else {
      throw new Error()
    }
  } catch (error) {
    clearUser()
    throw new Error(
      error.message ||
        'We experienced an issue attempting to log you in and our team has been notified. Please try again later.'
    )
  }
}

/* API request code login user with oauth using cookies*/
export async function loginWithCookies(cookies) {
  let user
  if (!cookies) {
    throw new Error('Cookies are empty')
  }
  const { user: userString, access_token } = parseCookie(cookies)
  if (access_token && user) {
    user = JSON.parse(userString)
  } else {
    throw new Error('Unable to parse cookies')
  }

  await _boot({ user, access_token })
}

async function _boot({ access_token }) {
  // handle replication delay
  await asyncSleep(REPLICATION_DELAY_MS)

  try {
    clearUser()
    setToken(access_token)
    sdkInterface.setAuthToken(access_token)

    await updateLocalStorage()

    useSuggestions().clearSuggestions()
  } catch (error) {
    console.error(error)
  }
}

function _getUrl(type, connect = false) {
  const loginMethod = LOGIN_METHODS.find((method) => method.type === type)

  if (connect && loginMethod?.connectUrl) {
    return loginMethod.connectUrl
  } else if (!connect && loginMethod?.url) {
    return loginMethod.url
  } else {
    throw new Error('Invalid login service')
  }
}
