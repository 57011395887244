import { isArray, isString, clone } from '@helpers/utils.js'
import { computed, nextTick, ref, watch } from 'vue'
import isEqual from 'lodash/isEqual'

export const MY_GAMES_FILTER_OPTION = 'My Games'
export const SUBSCRIBED_MODS_FILTER_OPTION = 'My Subscriptions'
export const MY_FILTER_NAME = 'Filter'

const GAME_FILTER_ITEM = [
  {
    hidden: true,
    name: MY_FILTER_NAME,
    selected: [],
    tags: [MY_GAMES_FILTER_OPTION],
    type: 'dropdown',
  },
]

const MOD_FILTER_ITEM = [
  {
    hidden: true,
    name: MY_FILTER_NAME,
    selected: [],
    tags: [SUBSCRIBED_MODS_FILTER_OPTION],
    type: 'dropdown',
  },
]

const state = ref({})
const loadingRef = ref({})
const hasBeenReset = ref({})

export default function (id, { showEmpty = false, showHidden = false } = {}) {
  if (!id) {
    throw new Error('useFilter requires id')
  }
  if (loadingRef.value[id] === undefined) {
    loadingRef.value[id] = true
  }

  const filter = computed(() => (state.value[id] ? state.value[id] : []))

  const loading = computed(() => loadingRef.value[id])

  const activeFilters = computed(() =>
    _getSelected(filter.value).map(({ id, filter, tag, type }) => ({
      id,
      filter,
      tag,
      active: true,
      type,
    }))
  )

  const isFiltering = computed(() => _getSelected(filter.value).length > 0)

  const getFilter = computed(() =>
    state.value[id] ? { 'tags-in': _getQueryString() } : null
  )

  const sideNavFilters = computed(() => {
    return state.value[id]
      ? state.value[id]
          .filter(({ hidden, groupDeleted, name }) => {
            const show = showHidden || !hidden
            return show && !groupDeleted && name !== MY_FILTER_NAME
          })
          .map((tag) => (showEmpty ? tag : _removeTagsWithNoRecords(tag)))
      : []
  })

  function _removeTagsWithNoRecords(tag) {
    if (tag.tag_count_map || tag.count) {
      const temp = clone(tag)
      const tags = temp.tags.filter(
        (t) =>
          (tag.tag_count_map?.[t] || 0) !== 0 || (tag.count?.[t] || 0) !== 0
      )
      temp.tags = tags
      return temp
    }
    return tag
  }

  function updateFilter(option) {
    const updateTags = [...state.value[id]]
    const index = updateTags.findIndex((tag) => tag.name === option.filter)

    if (index !== -1) {
      const tag = { ...updateTags[index] }

      switch (option.type) {
        case 'dropdown':
          tag.selected = _handleDropboxes(tag, option)
          break
        case 'checkboxes':
          tag.selected = _handleCheckboxes(tag, option)
          break
      }

      updateTags.splice(index, 1, tag)
    }

    if (isEqual(state.value[id], updateTags)) return

    state.value[id] = [...updateTags]
  }

  function resetFilter(tagOption) {
    const newFilters = state.value?.[id]?.map((filter) => ({
      ...filter,
      selected: !tagOption || filter.name === tagOption ? [] : filter.selected,
    }))

    state.value[id] = newFilters || []
    hasBeenReset.value[id] = true
  }

  function setFilter(options) {
    // set filter after the next dom update
    setFilterLoadingDone()

    nextTick(() => {
      if (!isArray(options)) {
        state.value[id] = []
      } else {
        state.value[id] = [...options]
      }
    })
  }

  function setFilterLoadingDone() {
    // set loadingRef to false after the next dom update
    nextTick(() => (loadingRef.value[id] = false))
  }

  function isFiltered() {
    return filter.value && filter.value.length !== 0
  }

  function inMemoryFilter(items) {
    let resultsCopy = items && clone(items)

    if (activeFilters.value.length > 0) {
      activeFilters.value
        .filter((f) => f.tag !== SUBSCRIBED_MODS_FILTER_OPTION)
        .forEach(({ tag }) => {
          resultsCopy = resultsCopy.filter(
            (item) =>
              !item.tags ||
              item.tags.find(
                ({ name }) => name.toLowerCase() === tag.toLowerCase()
              )
          )
        })
    }

    return resultsCopy ? resultsCopy : []
  }

  function watchFilters(callback, immediate = true) {
    watch(
      filter,
      (value) => {
        callback(value)
      },
      { immediate }
    )
  }

  function _handleDropboxes(tag, options) {
    return tag.selected.includes(options.value) ? [] : [options.value]
  }

  function _handleCheckboxes(tag, options) {
    return tag.selected.includes(options.value)
      ? tag.selected.filter((item) => item !== options.value)
      : [...tag.selected, options.value]
  }

  function _getSelected(filters) {
    const selected = []

    if (filters) {
      filters
        .filter((filter) => filter.selected.length !== 0)
        .forEach((filtered) => {
          filtered.selected.forEach((tagName, index) => {
            selected.push({
              id: index,
              filter: filtered.name,
              tag: tagName,
              type: filtered.type,
            })
          })
        })
    }

    return selected
  }

  function _getQueryString() {
    const selected = _getSelected(state.value[id]).map((select) => select.tag)

    return selected.length === 0
      ? null
      : selected
          .filter((tag) => tag !== SUBSCRIBED_MODS_FILTER_OPTION)
          .join(',')
  }

  return {
    setFilterLoadingDone,
    inMemoryFilter,
    sideNavFilters,
    activeFilters,
    updateFilter,
    watchFilters,
    resetFilter,
    isFiltering,
    isFiltered,
    setFilter,
    getFilter,
    loading,
    filter,
  }
}

export function normalizeModFilters(options, filterQuery) {
  // default mod filter item
  const filters = [...MOD_FILTER_ITEM, ...options]
  return filterQuery && isString(filterQuery)
    ? _mergeFilterQuery(filterQuery, filters)
    : filters
}

export function normalizeGameFilters(filterQuery) {
  // default game filter item
  const filter = GAME_FILTER_ITEM
  return filterQuery && isString(filterQuery)
    ? _mergeFilterQuery(filterQuery, filter)
    : filter
}

export function normalizeGuideFilters(filters, filterQuery) {
  const options = _getGuideFilterOptions(filters)
  return filterQuery && isString(filterQuery)
    ? _mergeFilterQuery(filterQuery, options)
    : options
}

function _getGuideFilterOptions(filters) {
  const tags = filters.map((filter) => filter.name).filter((n) => n.length)

  return [
    {
      hidden: false,
      name: 'Filter',
      selected: [],
      tags,
      type: 'checkboxes',
    },
  ]
}

function _mergeFilterQuery(filterQuery, options) {
  filterQuery = filterQuery.toLowerCase()

  const filterQueryArray = filterQuery.split(',')

  if (options.tags && options.tags.length === 0) {
    options.tags = filterQueryArray
  }

  const filters = clone(options)

  filters.forEach((filter, index) => {
    const tagOptions = filter.tags.map((tag) => tag.toLowerCase())
    filterQueryArray.forEach((query) => {
      if (tagOptions.includes(query)) {
        filters[index].selected.push(
          filter.tags.find((t) => t.toLowerCase() === query)
        )
      }
    })
  })

  return filters
}
