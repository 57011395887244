import { ref, computed } from 'vue'

// activeTip
const state = ref('')

export default function () {
  const activeTip = computed(() => state.value)

  function toggleTip(tip) {
    if (tip === state.value) {
      state.value = ''
    } else {
      state.value = tip
    }
  }

  return {
    activeTip,
    toggleTip,
  }
}
