<template>
  <div :id="anchorId" class="tw-w-full" :class="{ 'tw-mb-4': contentHeader }">
    <div
      class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between lg:tw-space-x-4 tw-space-y-4 lg:tw-space-y-0"
      :class="[customClass]"
    >
      <div class="tw-w-full">
        <loading-title v-if="panelLoading"></loading-title>
        <h2
          v-if="!panelLoading && text"
          class="tw-flex tw-text-h6 sm:tw-text-h5 lg:tw-text-h4"
          :class="[!status && statusBgStyle, textAlign]"
        >
          {{ text }}{{ status ? ': ' : '' }}
          <span v-if="status" class="tw-pl-1.5" :class="statusBgStyle">
            {{ status }}
          </span>

          <div
            v-if="$slots.tip"
            class="tw-flex tw-items-center sm:tw-items-end tw-flex-1 tw-justify-end"
          >
            <slot name="tip" />
          </div>
        </h2>

        <div
          v-if="$slots.content || $slots.noHtmlContent"
          class="tw-w-full tw-mt-2"
          :class="[textAlign, { 'tw-mb-6': basePanelHasContent }]"
        >
          <loading-content v-if="panelLoading" hide-title></loading-content>
          <div v-else class="tw-content">
            <p v-if="$slots.noHtmlContent">
              <slot name="noHtmlContent" />
            </p>
            <slot v-else name="content" />
          </div>
        </div>
      </div>

      <div
        v-if="$slots.buttons && !panelLoading"
        class="tw-flex tw-flex-col tw-justify-end tw-space-y-2"
      >
        <slot name="buttons" />
      </div>
    </div>
  </div>
</template>
<script>
import LoadingContent from '@components/Loading/LoadingContent.vue'
import LoadingTitle from '@components/Loading/LoadingTitle.vue'
import { computed, toRefs, getCurrentInstance } from 'vue'
export default {
  components: {
    LoadingTitle,
    LoadingContent,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    contentHeader: {
      type: Boolean,
      default: false,
    },
    panelLoading: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    anchorId: {
      type: String,
      default: undefined,
    },
    align: {
      type: String,
      default: 'left',
      validator: function (value) {
        return ['left', 'center', 'right'].indexOf(value) !== -1
      },
    },
    primary: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { align, primary, success, danger, warning, info } = toRefs(props)

    const textAlign = computed(() => {
      if (align.value === 'center') {
        return 'tw-justify-center tw-text-center'
      } else if (align.value === 'right') {
        return 'tw-justify-end tw-text-right'
      } else {
        return 'tw-justify-start tw-text-left'
      }
    })

    const statusBgStyle = computed(() => {
      if (success.value) {
        return 'tw-text-success'
      } else if (danger.value) {
        return 'tw-text-danger'
      } else if (warning.value) {
        return 'tw-text-warning'
      } else if (info.value) {
        return 'tw-text-info'
      } else if (primary.value) {
        return 'tw-text-primary'
      } else {
        return ''
      }
    })

    const basePanelHasContent = computed(() =>
      getCurrentInstance()?.parent?.slots?.default ? true : false
    )

    return {
      basePanelHasContent,
      statusBgStyle,
      textAlign,
    }
  },
}
</script>
