export const LS_DARK = 'dark'
export const LS_MESSAGE = 'message'
export const LS_RECENTGAMES = 'recent-games'
export const LS_INVITE = 'invite'
export const LS_ACCESSTOKEN = 'access-token'
export const LS_UPDATEDAT = 'updated-at'
export const LS_USER = 'user'
export const LS_TEAMS = 'teams'
export const LS_RATINGS = 'ratings'
export const LS_SUBSCRIPTIONS = 'subscriptions'
export const LS_LOCAL_MODS = 'local-mods'
export const LS_ACTIVATIONS = 'activations'
export const LS_USER_SUBSCRIPTIONS = 'user-subscriptions'
export const LS_REDIRECT = 'redirect'
export const LS_COOKIE = 'cookies'
export const LS_CONNECTING = 'connecting'
export const LS_PENDING_EMAIL = 'pending-email'
export const LS_CACHE_END_TS = 'cache-end-ts'
export const LS_KARMA = 'karma'
