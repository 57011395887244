import { isObj, clone } from '@helpers/utils.js'
import gameDefaults from '@types/game.js'
import Serializer from './Serializer.js'
import {
  GAME_MOD_SUBMISSION_OPTIONS,
  GAME_PRESENTATION_OPTIONS,
  GAME_MOD_CURATION_OPTIONS,
  GAME_TAG_TYPE_OPTIONS,
  STATUS_OPTIONS,
} from '@config/options.js'

const gameOptions = {
  status_options: STATUS_OPTIONS,
  presentation_options: GAME_PRESENTATION_OPTIONS,
  tag_types: GAME_TAG_TYPE_OPTIONS,
  submission_options: GAME_MOD_SUBMISSION_OPTIONS,
  curation_options: GAME_MOD_CURATION_OPTIONS,
}

const transformations = [
  _transformTeam,
  _flatten(['user', 'team', 'tag_options', 'theme', 'api_key']),
  transformTags,
  _maskConnections,
  _transformAgreement,
]

const cogsGameSerializer = new Serializer({
  transformations,
  options: gameOptions,
  defaults: gameDefaults,
})

function _transformTeam(game) {
  let gameCopy = { ...game }
  gameCopy.team = [...game.team]

  return gameCopy
}

function _maskConnections(game) {
  const connections = [
    'google_analytics',
    'steam_ticket',
    'gog_ticket',
    'switch_app_id',
    'oculus_rift_id',
    'oculus_rift_secret',
    'oculus_quest_id',
    'oculus_quest_secret',
  ]

  connections.forEach((connection) => {
    if (game[connection]) {
      let text = game[connection].replace(/\*/g, '').replace(' ', '')

      const len = text.length
      const mid = Math.ceil(len / 2)
      const first = text.slice(0, mid)
      const last = text.slice(mid)

      game[connection] = [first, last].join('*'.repeat(25))
    }
  })

  return game
}

function _transformAgreement(game) {
  let gameCopy = { ...game }
  if (gameCopy.agreement) {
    gameCopy.agreement = gameCopy.agreement ? 1 : 0
  }

  return gameCopy
}

function _flatten(skip = []) {
  return (game) => {
    let gameCopy = {}

    Object.keys(game).forEach((key) => {
      if (!skip.includes(key)) {
        gameCopy = { ...gameCopy, ...game[key] }
      } else {
        gameCopy[key] = game[key]
      }
    })

    return gameCopy
  }
}

export function transformTags(game) {
  const gameCopy = clone(game)
  if (gameCopy.tag_options) {
    gameCopy.tag_options = _setTagIds(gameCopy.tag_options)
  }

  return gameCopy
}

function _setTagIds(tags) {
  tags.forEach((tag, index) => {
    tag.id = `T-${index}`
    tag.selected = []
    // Handle cases where tags are saved as an object in API
    tag.tags = isObj(tag.tags) ? Object.values(tag.tags) : tag.tags
  })
  return tags
}

export default cogsGameSerializer
