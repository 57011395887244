<template>
  <loading-input
    v-if="inputLoading"
    :label="label"
    :type="type"
    :cols="cols"
    :rows="rows"
  />

  <div v-else class="tw-text-theme" :class="wrapWidth">
    <div
      v-if="tip || label"
      class="tw-flex tw-justify-between tw-label-gap tw-space-x-1"
    >
      <base-input-label
        v-if="label"
        :id-for="inputId"
        :required="required"
        :label="label"
      />

      <tip-button v-if="tip" :tip="tip" />
    </div>

    <div class="tw-group">
      <!-- textarea -->
      <textarea
        v-if="type === 'textarea'"
        :id="inputId"
        ref="textInputElement"
        :value="dataValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="max || undefined"
        :cols="cols"
        :rows="rows"
        :aria-describedby="`${inputId}Help`"
        :tabindex="disabled ? '-1' : '0'"
        class="tw-flex tw-input--p tw-resize-y tw-min-h-18 tw-transition-colors tw-ease-in-out tw-bg-input-group-hover tw-input--text-size"
        :class="[
          inputBaseStyles,
          inputWidth,
          hasErrors
            ? 'tw-border-danger tw-border-2'
            : !disabled
            ? 'tw-input--focus'
            : '',
        ]"
        @keyup="handleKeyUp"
        @input="onInput($event.target.value)"
        @change="$emit('change', dataValue)"
        @focus="setFocus"
        @blur="setBlur"
      />

      <!-- input -->
      <div
        v-else
        class="tw-flex tw-overflow-hidden tw-relative tw-button-transition"
        :class="[
          inputWidth,
          hasErrors
            ? 'tw-border-danger tw-border-2'
            : !disabled
            ? 'tw-input--focus-within'
            : 'tw-border-2 tw-border-transparent',
          whiteBg
            ? 'tw-bg-white tw-text-light-text'
            : altBg
            ? 'tw-bg-input-alt-hover '
            : 'tw-bg-input-hover ',
          small
            ? 'tw-input--height-small tw-text-sm'
            : 'tw-input--height tw-input--text-size',
        ]"
      >
        <!-- prepend -->
        <div
          v-if="$slots.prepend || $slots.prependText"
          class="tw-prepend tw-flex tw-h-full"
          :class="[inputBaseStyles, prependText]"
        >
          <div
            class="tw-flex tw-items-center tw-relative tw-h-full"
            :class="{ 'tw-top-px': $slots.prependText }"
          >
            <slot v-if="$slots.prepend" name="prepend" />
            <slot v-if="$slots.prependText" name="prependText" />
          </div>
        </div>

        <!-- input -->
        <input
          :id="inputId"
          ref="inputElement"
          :name="name"
          :value="url ? removeProtocol(dataValue) : dataValue"
          :placeholder="placeholder"
          :disabled="disabled"
          :min="isNumberInput ? min : null"
          :max="isNumberInput ? max : null"
          :maxlength="max || undefined"
          :type="inputType"
          :aria-describedby="`${inputId}Help`"
          :tabindex="disabled ? '-1' : '0'"
          class="tw-flex tw-h-full tw-bg-transparent tw-placeholder-input"
          :class="[
            inputBaseStyles,
            inputWidth,
            inputHasPrepend,
            inputHasPrependText,
            inputHasAppend,
            inputHasAppendText,
          ]"
          @keyup="handleKeyUp"
          @input="onInput($event.target.value)"
          @change="$emit('change', dataValue)"
          @keypress.enter="handleEnter"
          @focus="setFocus"
          @blur="setBlur"
        />

        <!-- append -->
        <div
          v-if="$slots.append || $slots.appendText"
          class="tw-append tw-flex tw-h-full"
          :class="[inputBaseStyles, appendText]"
        >
          <div
            class="tw-flex tw-items-center tw-relative tw-h-full"
            :class="{ 'tw-top-px': $slots.appendText }"
          >
            <slot v-if="$slots.append" name="append" />
            <slot v-if="$slots.appendText" name="appendText" />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="hasCountOrHint || hasErrors"
      class="tw-flex tw-flex-col tw-hint-gap tw-space-y-1"
    >
      <base-input-errors v-if="!hideErrorText" :errors="errors" align="left" />

      <character-count
        v-if="showCount"
        class="tw-button-transition tw-opacity-50"
        :count="dataValue ? dataValue.length : 0"
        :min="min"
        :max="max"
      />

      <base-input-hint v-if="hint" :id="`${inputId}Help`">
        {{ hint }}
      </base-input-hint>
    </div>
  </div>
</template>

<script>
import { genHtmlId, removeProtocol, isObjEmpty, isObj } from '@helpers/utils.js'
import CharacterCount from '@components/Input/CharacterCount.vue'
import LoadingInput from '@components/Loading/LoadingInput.vue'
import { watch, computed, onMounted, ref, toRefs } from 'vue'
import TipButton from '@components/Tip/TipButton.vue'
export default {
  components: {
    CharacterCount,
    LoadingInput,
    TipButton,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    modelValue: {
      type: null,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    cols: {
      type: String,
      default: '30',
    },
    rows: {
      type: String,
      default: '10',
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    tip: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    focus: {
      type: Boolean,
      default: false,
    },
    url: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      required: true,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    altBg: {
      type: Boolean,
      default: false,
    },
    whiteBg: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hideErrorText: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'change', 'focus', 'blur', 'enter', 'validate'],
  setup(props, { emit, slots }) {
    const {
      modelValue,
      fullWidth,
      showCount,
      disabled,
      errors,
      focus,
      hint,
      type,
      min,
      max,
      url,
      id,
    } = toRefs(props)

    const inputId = id.value ? id.value : genHtmlId()

    const inputElement = ref(null)
    const textInputElement = ref(null)
    const isFocused = ref(false)
    const hasErrors = computed(() => !isObjEmpty(errors.value))
    const dataValueState = ref(modelValue.value)
    const dataValue = computed(() => dataValueState.value)

    watch(modelValue, (value) => {
      if (isObj(value)) {
        if (value.start === null && value.end === null) {
          dataValueState.value = ''
        } else {
          dataValueState.value = `${value.start} - ${value.end}`
        }
      } else {
        dataValueState.value = value
      }
    })

    onMounted(() => {
      if (focus.value) {
        if (textInputElement.value) {
          textInputElement.value.focus()
        }

        if (inputElement.value) {
          inputElement.value.focus()
        }
      }
    })

    const isNumberInput = computed(() => type.value === 'number')

    const hasCountOrHint = computed(
      () =>
        (showCount.value && (min.value !== 0 || max.value !== 0)) ||
        hint.value !== ''
    )

    const inputBaseStyles = computed(() => {
      let classList = 'tw-leading-normal tw-outline-none tw-appearance-none'
      if (disabled.value) {
        return `${classList} tw-cursor-default`
      }
      return classList
    })

    const inputWidth = computed(() => {
      if (fullWidth.value) {
        return 'tw-w-full'
      }
      return ''
    })

    const inputHasPrepend = computed(() => {
      if (slots.prepend || slots.prependText) {
        return ''
      }
      return ''
    })

    const inputHasAppend = computed(() => {
      if (slots.append || slots.appendText) {
        return ''
      }
      return ''
    })

    const inputHasPrependText = computed(() => {
      if (slots.prependText) {
        return 'tw-pl-1'
      } else if (slots.prepend) {
        return 'tw-pl-2'
      }
      return 'tw-input--pl'
    })

    const inputHasAppendText = computed(() => {
      if (slots.appendText) {
        return 'tw-pr-1'
      } else if (slots.append) {
        return 'tw-pr-2'
      }
      return 'tw-input--pr'
    })

    const prependText = computed(() => {
      if (slots.prependText) {
        return 'tw-input--pl tw-pr-px'
      }
      return 'tw-input--pl tw-pr-1'
    })

    const appendText = computed(() => {
      if (slots.appendText) {
        return 'tw-pl-px tw-pr-2'
      }
      return 'tw-pl-1 tw-input--pr'
    })

    const wrapWidth = computed(() => {
      if (fullWidth.value) {
        return 'tw-block tw-w-full'
      }
      return 'tw-inline-block'
    })

    const inputType = computed(() => {
      return url.value ? 'url' : type.value
    })

    function setFocus() {
      isFocused.value = true
      emit('focus')
    }

    function setBlur(event) {
      isFocused.value = false
      emit('blur', event.target.value)
    }

    function onInput(value) {
      emit('input', value)
    }

    function handleKeyUp(event) {
      dataValueState.value = event.target.value
      emit('validate', event.target.value)
      if (event.code === 'Enter') {
        emit('enter')
      }
    }

    return {
      inputHasPrependText,
      inputHasAppendText,
      textInputElement,
      inputBaseStyles,
      inputHasPrepend,
      inputHasAppend,
      removeProtocol,
      hasCountOrHint,
      isNumberInput,
      inputElement,
      handleKeyUp,
      prependText,
      inputWidth,
      appendText,
      hasErrors,
      wrapWidth,
      dataValue,
      inputType,
      isFocused,
      setFocus,
      onInput,
      setBlur,
      inputId,
    }
  },
}
</script>

<style lang="postcss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
