import { store } from '@stores'

const { getStore, createStore } = store()
const headers = getStore('headers')

export default function () {
  function setPortal(portal) {
    createStore('headers', { ...headers.value, portal })
  }

  function setPlatform(platform) {
    createStore('headers', { ...headers.value, platform })
  }

  return {
    setPlatform,
    setPortal,
    headers,
  }
}
