import { WIDGET_PREFIX_ROUTE } from '@config/routeNames.js'
import { scrollTo, scrollIntoView } from 'scroll-js'
import * as lodashThrottle from 'lodash/throttle'
import { hostURL } from '@config/game.js'
import { getCurrentInstance } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import isURL from 'validator/lib/isURL'
import { customAlphabet } from 'nanoid'
import readingTime from 'reading-time'
import equal from 'lodash/isEqual'
import filesize from 'filesize'
import get from 'lodash/get'
import cookie from 'cookie'
import qs from 'qs'
import {
  ONLINE_USER_TIMEOUT_SECONDS,
  PHP_UNIXTIME_LEN,
  IMAGE_CROP_SIZES,
  LOCALE_LANGUAGE,
  SCROLL_TO_ID,
} from '@config'

export const MS_YEAR = 365 * 24 * 60 * 60 * 1000
export const MS_DAY = 24 * 60 * 60 * 1000
export const MS_HOUR = 60 * 60 * 1000
export const MS_MINUTE = 60 * 1000
export const MS_SECOND = 1000

export const HEADER_HEIGHT = 80

export const isClient = typeof window !== 'undefined'
export const defaultDocument = isClient ? window.document : undefined

/* istanbul ignore next */
export function isEqual(value, other) {
  return equal(value, other)
}

export function deleteCookie(name) {
  if (document?.cookie) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }
}

export function parseCookie(cookies) {
  return cookie.parse(cookies)
}

export function genRanId() {
  const nanoid = customAlphabet(
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
    21
  )

  return nanoid()
}

export function genHtmlId() {
  const instance = getCurrentInstance()
  return `input${instance?.uid || genRanId()}`
}

export function stringProper(text) {
  return text
    .toString()
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export function callOrReturn(input) {
  if (!input) {
    return
  } else if (isFunction(input)) {
    return input()
  } else {
    return input
  }
}

export function removeDupes(array) {
  return [...new Set(array)].filter((t) => t)
}

export function isFunction(val) {
  return toString.call(val) === '[object Function]'
}

export function isArray(val) {
  return toString.call(val) === '[object Array]'
}

export function isDate(val) {
  return toString.call(val) === '[object Date]'
}

export function isObj(val) {
  return toString.call(val) === '[object Object]'
}

export function isString(val) {
  return toString.call(val) === '[object String]'
}

export function isRegex(val) {
  return toString.call(val) === '[object RegExp]'
}

export function isFile(val) {
  return toString.call(val) === '[object File]'
}

export function isBoolean(val) {
  return (
    toString.call(val) === '[object Boolean]' ||
    val === 1 ||
    val === 0 ||
    val === '0' ||
    val === '1'
  )
}

export function isNumber(val) {
  return typeof val === 'number'
}

export function isObjEmpty(obj) {
  return isObj(obj) && Object.keys(obj).length === 0
}

export function isEven(index) {
  return index % 2 === 0
}

export function arrayWrap(item) {
  if (isArray(item)) {
    return item
  }

  return [item]
}

export function clone(data) {
  return cloneDeep(data)
}

export function addProtocol(url) {
  if (!url) return
  if (isURL(url, { require_protocol: true })) {
    return url
  } else if (isURL(url)) {
    return `https://${url}`
  }

  return url
}

export function removeProtocol(url) {
  if (!url) return

  if (!isURL(url, { require_protocol: true })) return url

  return url.replace(/^.+?:\/\//, '')
}

export function pad(input, count, char = '0') {
  if (input.toString().length >= count) return input.toString()

  const start = new Array(count).fill(char).join('')
  return (start + input).slice(-count)
}

export function queryString(query) {
  if (!isObj(query) || !query) {
    return ''
  }
  return `?${qs.stringify(query)}`
}

export function isWidget(path) {
  if (!path) path = new URL(window.location.href).pathname.toLowerCase()

  if (!path.includes(`/${WIDGET_PREFIX_ROUTE}`)) return false

  if (
    path.includes(`/m/${WIDGET_PREFIX_ROUTE}`) ||
    path.includes(`/r/${WIDGET_PREFIX_ROUTE}`)
  ) {
    return path.includes(`/${WIDGET_PREFIX_ROUTE}/${WIDGET_PREFIX_ROUTE}`)
  }

  return true
}

export function unixTime(date) {
  return isDate(date) ? parseInt((date.getTime() / 1000).toFixed(0)) : null
}

export function timeSince(unixTime, { short = true } = {}) {
  if (!unixTime) return ''
  const current = parseInt(Date.now().toFixed(0))
  const past = secsToMs(unixTime)
  if (past > current) {
    return ''
  }

  const diff = current - past

  if (diff >= MS_YEAR) {
    const years = Math.floor(diff / MS_YEAR)
    if (short) {
      return `${years}y`
    } else {
      return years === 1 ? `${years} year` : `${years} years`
    }
  } else if (diff >= MS_DAY) {
    const days = Math.floor(diff / MS_DAY)
    if (short) {
      return `${days}d`
    } else {
      return days === 1 ? `${days} day` : `${days} days`
    }
  } else if (diff >= MS_HOUR) {
    const hours = Math.floor(diff / MS_HOUR)
    if (short) {
      return `${hours}h`
    } else {
      return hours === 1 ? `${hours} hour` : `${hours} hours`
    }
  } else if (diff >= MS_MINUTE) {
    const mins = Math.floor(diff / MS_MINUTE)
    if (short) {
      return `${mins}m`
    } else {
      return mins === 1 ? `${mins} minute` : `${mins} minutes`
    }
  } else if (diff >= 0) {
    const secs = Math.floor(diff / MS_SECOND)
    if (short) {
      return `${secs}s`
    } else {
      return secs === 1 ? `${secs} second` : `${secs} seconds`
    }
  }
}

export function timeRemaining(unixTime) {
  if (!unixTime) return ''
  const current = msToSecs(Date.now())

  let timeRemaining = Math.max(unixTime - current, 0)

  const days = Math.floor(timeRemaining / 86400)
  timeRemaining -= days * 86400

  const hours = Math.floor(timeRemaining / 3600)
  timeRemaining -= hours * 3600

  const mins = Math.floor(timeRemaining / 60)
  timeRemaining -= mins * 60

  return `${days > 999 ? '+999' : days}:${pad(hours, 2)}:${pad(mins, 2)}:${pad(
    timeRemaining,
    2
  )}`
}

export function isOnlineUser(unixTime) {
  const current = parseInt(Date.now().toFixed(0))
  const lastOnline = secsToMs(unixTime)

  return lastOnline > current - secsToMs(ONLINE_USER_TIMEOUT_SECONDS)
}

export function humanDateFormat(timestamp) {
  if (!timestamp) {
    return ''
  }
  if (!isNumber(timestamp)) {
    return ''
  }

  let dateObject

  if (getNumberLength(timestamp) <= PHP_UNIXTIME_LEN) {
    dateObject = new Date(secsToMs(timestamp))
  } else {
    dateObject = new Date(timestamp)
  }

  return dateObject.toLocaleString(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
}

export function humanNumberFormat(number) {
  if (!isNumber(number)) {
    return 0
  }

  return number.toLocaleString(LOCALE_LANGUAGE)
}

export function humanFilesize(bits, options) {
  return filesize(bits, options)
}

export function getNumberLength(number) {
  return number.toString().length
}

export function proper(text) {
  if (!text || !isString(text)) {
    return
  }

  return text
    .split(' ')
    .map((word) => {
      const firstLetter = word.slice(0, 1)
      const rest = word.slice(1)
      return `${firstLetter.toUpperCase()}${rest.toLowerCase()}`
    })
    .join(' ')
}

export function flatten(data) {
  const newObj = {}
  Object.keys(data).forEach((key) => {
    if (data[key] && typeof data[key] === 'object') {
      Object.keys(data[key]).forEach((innerKey) => {
        newObj[innerKey] = data[key][innerKey]
      })
    } else {
      newObj[key] = data[key]
    }
  })

  return newObj
}

/* istanbul ignore next */
export function getReadingTime(string) {
  return readingTime(string)
}

/* istanbul ignore next */
export function throttle(fn, duration) {
  return lodashThrottle(fn, duration, {
    leading: false,
  })
}

export function objectGet(object, key) {
  return get(object, key)
}

export function secsToMs(secs) {
  return secs * MS_SECOND
}

export function msToSecs(ms) {
  return parseInt((ms / MS_SECOND).toFixed(0))
}

export function youtubeParser(url) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  var match = url.match(regExp)
  return match && match[7].length == 11 ? match[7] : false
}

export function numberFormatter(num, places = 0) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value)
  return item
    ? (num / item.value).toFixed(places).replace(rx, '$1') + item.symbol
    : '0'
}

/* istanbul ignore next */
export function asyncSleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

// Image crop using url format crop_320x180
export function getCropImageUrl({ originalUrl, size, type = 'crop' }) {
  // throws type error if not a valid url
  new URL(originalUrl)
  if (!IMAGE_CROP_SIZES.includes(size)) {
    throw new Error('Crop size not allowed.')
  }
  return originalUrl.replace(
    /(crop|thumb|image)_([0-9]+x[0-9]+)/,
    `${type}_${size}`
  )
}

export function separateCamelCase(str, separator = '-') {
  return str
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? separator : ''}${letter.toLowerCase()}`
        : letter
    })
    .join('')
}

export function toCamelCase(str, separator = '_') {
  return str
    .split(separator)
    .map((word, idx) =>
      idx === 0 ? word : word[0].toUpperCase() + word.slice(1).toLowerCase()
    )
    .join('')
}

export function validNameId(value) {
  return value
    .replace(/ /g, '-')
    .replace(/[^-a-zA-Z0-9]+/g, '')
    .replace(/[-]+/g, '-')
    .replace(/^-(.*)/g, '$1')
    .replace(/(.*)-$/g, '$1')
    .toLowerCase()
}

export function areBitsSet(value, mask) {
  return (value & mask) >= 1
}

export function setBits(value, mask) {
  return value | mask
}

export function unsetBits(value, mask) {
  return value & ~mask
}

export function generateSortQuery(direction, sortKey) {
  return direction === 'desc' ? `-${sortKey}` : sortKey
}

export function getOptionText({ options, value }) {
  return options.find((o) => o.value === value)?.text
}

export function apiErrorsToArray(error) {
  if (isObj(error) && !isObjEmpty(error)) {
    let arr = []
    Object.values(error).forEach((v) => {
      if (isObj(v) && !isObjEmpty(v)) {
        Object.values(v).forEach((x) => {
          if (isString(x)) arr.push(x)
        })
      } else {
        arr.push(v)
      }
    })
    return arr
  } else if (isString(error)) {
    return [error]
  } else {
    return null
  }
}

export function getQueryPortal() {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  return query.ref || query.portal
}

export function getProps(item, card) {
  const itemProps = {}
  card.footerProps &&
    isArray(card.footerProps) &&
    card.footerProps.forEach((element) => {
      itemProps[toCamelCase(element)] = item[element]
    })

  return itemProps
}

export function isNavigationInSameBrowseView(to, from) {
  return to.meta?.browse && to.name === from.name
}

export function isSameRouteChild(to, from) {
  return to.meta?.children?.includes(from.name) || from.fullPath === to.fullPath
}

export function isIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export function scrollIframe(to) {
  window.parent.postMessage({ modioScrollTo: to.scrollTop }, hostURL)
}

export function handleScroll(to, from) {
  // handles useBrowse push & widget
  if (
    isNavigationInSameBrowseView(to, from) ||
    to.hash ||
    isWidget(to.fullPath.toLowerCase())
  ) {
    return
  }

  const container = defaultDocument.getElementById(SCROLL_TO_ID)

  if (container) {
    const sameChild = isSameRouteChild(to, from)
    scrollTo(container, { top: 0, duration: sameChild ? undefined : 0 })
    scrollIframe(container)
  }
}

export function scrollToElement(id, timeout = 0) {
  const container = defaultDocument.getElementById(SCROLL_TO_ID)
  const target = defaultDocument.getElementById(id)

  if (target) {
    setTimeout(() => {
      scrollIntoView(target, container, {
        duration: 300,
        easing: 'ease-in-out',
      })
      scrollIframe(target)
    }, timeout)
  }
}

export function scrollToElementOffset(id, offset = -HEADER_HEIGHT) {
  const container = defaultDocument.getElementById(SCROLL_TO_ID)
  const target = defaultDocument.getElementById(id)
  if (target) {
    const rect = target.getBoundingClientRect()
    const elementTop = container.scrollTop + rect.top
    scrollTo(container, {
      top: elementTop + offset,
      duration: 300,
      easing: 'ease-in-out',
    })
    scrollIframe(container)
  }
}

export function getQueryFromPath(url) {
  return url.includes('?') ? qs.parse(url.split('?').slice(-1)[0]) : {}
}

export function hasDynamicBreadcrumbName(name) {
  return name.startsWith(':')
}

export function apostrophes(val) {
  if (!isString(val)) return val
  return val.slice(-1) === 's' ? val : `${val}'s`
}

export function getOtherURLType(url) {
  if (url.match(/steampowered/i)) return 'Steam'
  if (url.match(/xbox|microsoft|windows/i)) return 'Xbox'
  if (url.match(/sony|playstation/i)) return 'PlayStation'
  if (url.match(/nintendo|switch/i)) return 'Switch'
  if (url.match(/oculus|rift|quest/i)) return 'Oculus'
  if (url.match(/google/i)) return 'Android'
  if (url.match(/apple/i)) return 'iOS'
  if (url.match(/discord/i)) return 'Discord'
  if (url.match(/itch\.io/i)) return 'itch.io'
  if (url.match(/gog/i)) return 'GOG.com'
  if (url.match(/facebook/i)) return 'Facebook'
  if (url.match(/twitter/i)) return 'Twitter'
  if (url.match(/youtube/i)) return 'Youtube'
  if (url.match(/twitch/i)) return 'Twitch'
  if (url.match(/epicgames/i)) return 'Epic Games'
  if (url.match(/instagram/i)) return 'Instagram'
  if (url.match(/reddit/i)) return 'Reddit'
  if (url.match(/indiedb/i)) return 'IndieDB'
  if (url.match(/moddb/i)) return 'ModDB'
  if (url.match(/gamejolt/i)) return 'Gamejolt'
  return null
}

export function startsWithVowel(val) {
  if (!isString(val) || val.length === 0) return false
  return 'aeiou'.includes(val[0].toLowerCase())
}

export function arrayMove(array, from, to) {
  if (from > array.length - 1 || from < 0 || to < 0)
    throw new Error('index out of bounds')

  array.splice(to, 0, array.splice(from, 1)[0])
  return array
}

const cleanElem = document.createElement('div') // here so it's not recreated every time the function is called
export function cleanHTMLEntities(text) {
  cleanElem.innerHTML = text
  return cleanElem.textContent
}
