<template>
  <teleport to="body">
    <login-modal
      :show="globalModal === MODAL_LOGIN"
      @close:loginmodal="clearGlobalModal"
      @login:success="handleLogin"
    />

    <loading-modal
      :show="globalModal === MODAL_LOADING"
      :status="globalModalData?.status"
      :message="globalModalData?.message"
      :button-text="globalModalData?.buttonText"
    />

    <report-modal
      :show="globalModal === MODAL_REPORT"
      :resource="globalModalData?.resource"
      :resource-id="globalModalData?.resourceId"
      @close:report="clearGlobalModal"
    />
  </teleport>
</template>

<script>
import { LOGIN_ROUTE, GAME_BROWSE_ROUTE } from '@config/routeNames.js'
import { AGREEMENT_TYPE_OPTIONS } from '@config/options.js'
import { LS_REDIRECT } from '@config/localstorage.js'
import { defineAsyncComponent, computed } from 'vue'
import { getQueryFromPath } from '@helpers/utils.js'
import * as MODALS from '@config/globalModals.js'
import { useRoute } from '@composables'
import {
  clearGlobalModal,
  globalModalData,
  globalModal,
} from '@composables/useModal.js'

export default {
  components: {
    LoadingModal: defineAsyncComponent(() =>
      import('@components/Modals/LoadingModal.vue')
    ),
    ReportModal: defineAsyncComponent(() =>
      import('@components/Modals/ReportModal.vue')
    ),
    LoginModal: defineAsyncComponent(() =>
      import('@components/Modals/LoginModal.vue')
    ),
  },
  setup() {
    const { push, routeName } = useRoute()

    const agreementHeadline = computed(
      () =>
        AGREEMENT_TYPE_OPTIONS.find(
          (t) => t.value === globalModalData.value?.agreementType
        )?.text || ''
    )

    function handleLogin() {
      const redirectURL = localStorage.getItem(LS_REDIRECT)
      if (redirectURL) {
        const query = getQueryFromPath(redirectURL)
        localStorage.setItem(LS_REDIRECT, '')
        push({ path: redirectURL, query })
      } else if (routeName.value === LOGIN_ROUTE) {
        push({ name: GAME_BROWSE_ROUTE })
      }
    }

    return {
      agreementHeadline,
      clearGlobalModal,
      globalModalData,
      globalModal,
      handleLogin,
      ...MODALS,
    }
  },
}
</script>
