import { LS_ACTIVATIONS } from '@config/localstorage.js'
import { useLocalStorage } from '@vueuse/core'
import { isArray } from '@helpers/utils.js'
import { computed } from 'vue'

const activationState = useLocalStorage(LS_ACTIVATIONS, null)
const KEY_LOCAL = 'local'
const KEY_MODIO = 'mod.io'

export default function () {
  const activations = computed(() => JSON.parse(activationState.value))

  function clearActivations() {
    activationState.value = JSON.stringify(null)
  }

  function setMyActivations(modIds, local) {
    activationState.value = JSON.stringify({
      ...activations.value,
      [getKey(local)]: modIds,
    })
  }

  // use game and mod id
  function addActivation(modId, local) {
    let state

    const key = getKey(local)
    if (isArray(activations.value?.[key])) {
      state = [...new Set([...activations.value[key], modId])]
    } else {
      state = [modId]
    }

    setMyActivations(state, local)
  }

  // use game and mod id
  function removeActivation(modId, local) {
    if (activations.value?.[getKey(local)]) {
      const updated = activations.value[getKey(local)].filter(
        // != as int or string
        (activationId) => activationId != modId
      )

      setMyActivations(updated, local)
    }
  }

  function getKey(local) {
    return local ? KEY_LOCAL : KEY_MODIO
  }

  return {
    removeActivation,
    setMyActivations,
    clearActivations,
    addActivation,
    activations,
    getKey,
  }
}
