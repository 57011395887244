<template>
  <loading-button
    v-if="inputLoading"
    :small="small"
    :large="large"
    :full-width="fullWidth"
    :input-btn="inputBtn"
    :full-width-at="fullWidthAt"
  ></loading-button>

  <button
    v-else
    :id="inputId"
    :type="type"
    :disabled="isDisabled"
    v-bind="$attrs"
    :class="[
      buttonTopLevelStyles,
      buttonDisabledBg,
      buttonUppercase,
      buttonTypeHover,
      buttonTextSize,
      buttonPadding,
      buttonRadius,
      buttonBorder,
      buttonCursor,
      buttonHeight,
      buttonWidth,
      buttonType,
    ]"
    :tabindex="disabled || noTab ? '-1' : '0'"
    @click="onClick"
  >
    <!-- loader -->
    <base-loading v-show="hasStatus" :status="status || thisStatus" />

    <!-- icon -->
    <template v-if="icon && !hasStatus">
      <base-icon v-if="baseIcon" :icon="icon" class="tw-w-6 tw-h-6" />
      <font-awesome-icon
        v-else
        :class="iconColor"
        :icon="icon"
        :size="iconSize"
        fixed-width
      />
    </template>

    <!-- slot data -->
    <span
      v-if="$slots.default && !hasStatus"
      class="tw-transform tw-transition-transform"
      :class="[textAnimation, { 'tw-w-full': slotFullWidth }]"
    >
      <slot />
    </span>

    <!-- screen reader text -->
    <span v-if="srOnly" class="sr-only">{{ srOnly }}</span>
  </button>
</template>

<script>
import LoadingButton from '@components/Loading/LoadingButton.vue'
import { genHtmlId, isObjEmpty } from '@helpers/utils.js'
import { useStatus } from '@composables'
import { computed, toRefs } from 'vue'
export default {
  components: {
    LoadingButton,
  },
  props: {
    basicBtn: {
      type: Boolean,
      default: false,
    },
    inputBtn: {
      type: Boolean,
      default: false,
    },
    textLink: {
      type: Boolean,
      default: false,
    },
    textLinkHover: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    subtle: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    hollow: {
      type: Boolean,
      default: false,
    },
    noBg: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    slotFullWidth: {
      type: Boolean,
      default: false,
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
    fullWidthAt: {
      type: String,
      default: '',
      validator: function (value) {
        return ['xl', 'lg', 'md', 'sm', 'xs', ''].indexOf(value) !== -1
      },
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: String,
      default: 'none',
      validator: function (value) {
        return ['full', 'bottom', 'none', 'default'].indexOf(value) !== -1
      },
    },
    icon: {
      type: [String, Array],
      default: null,
    },
    iconSize: {
      type: String,
      default: null,
    },
    baseIcon: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    id: {
      type: String,
      default: null,
    },
    srOnly: {
      type: String,
      default: '',
    },
    noTab: {
      type: Boolean,
      default: false,
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: 'tw-fill-current',
    },
  },
  setup(props) {
    const {
      textLinkHover,
      fullWidthAt,
      uppercase,
      fullWidth,
      autoWidth,
      secondary,
      disabled,
      basicBtn,
      inputBtn,
      textLink,
      warning,
      success,
      primary,
      errors,
      hollow,
      radius,
      status,
      danger,
      subtle,
      xsmall,
      large,
      small,
      info,
      icon,
      noBg,
      id,
    } = toRefs(props)

    const inputId = id.value ? id.value : genHtmlId()
    const {
      status: thisStatus,
      statusType,
      watchStatusAnimation,
      updateStatus,
    } = useStatus()

    const { isLoading, isSuccess, isError } = watchStatusAnimation(status)

    const isTextLinkItem = computed(() => textLink.value || textLinkHover.value)

    const isDisabled = computed(
      () => status.value === statusType.LOADING || disabled.value
    )

    const hasStatus = computed(
      () =>
        isLoading.value || isSuccess.value || isError.value || thisStatus.value
    )

    const buttonTopLevelStyles =
      'tw-group tw-overflow-hidden tw-button-transition tw-leading-normal tw-outline-none tw-shrink-0 tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-font-bold'

    const buttonWidth = computed(() => {
      if (fullWidth.value) {
        return 'tw-w-full'
      } else if (basicBtn.value || autoWidth.value) {
        return ''
      } else if (inputBtn.value) {
        if (large.value) {
          return 'tw-input--width-large'
        } else if (small.value) {
          return 'tw-input--width-small'
        } else if (xsmall.value) {
          return 'tw-input--width-xsmall'
        }
        return 'tw-input--width'
      } else {
        if (fullWidthAt.value === 'xl') {
          return `tw-w-full xl:tw-w-36`
        } else if (fullWidthAt.value === 'lg') {
          return `tw-w-full lg:tw-w-36`
        } else if (fullWidthAt.value === 'md') {
          return `tw-w-full md:tw-w-36`
        } else if (fullWidthAt.value === 'sm') {
          return `tw-w-full sm:tw-w-36`
        } else if (fullWidthAt.value === 'xs') {
          return `tw-w-full xs:tw-w-36`
        } else {
          return 'tw-w-36'
        }
      }
    })

    const buttonHeight = computed(() => {
      if (large.value && !basicBtn.value) {
        return 'tw-input--height-large'
      } else if (small.value) {
        return 'tw-input--height-small'
      } else if (basicBtn.value) {
        return ''
      } else {
        return 'tw-input--height'
      }
    })

    const buttonTextSize = computed(() => {
      if (large.value) {
        return 'tw-text-md'
      } else if (small.value) {
        return 'tw-text-sm'
      }
      return 'tw-text-sm'
    })

    const buttonCursor = computed(() => {
      if (hasStatus.value) {
        return 'tw-cursor-default'
      } else if (disabled.value || isDisabled.value) {
        return 'tw-cursor-not-allowed'
      } else {
        return 'tw-cursor-pointer'
      }
    })

    const buttonDisabledBg = computed(() => {
      if (disabled.value) {
        return 'tw-opacity-40'
      }
      return ''
    })

    const buttonType = computed(() => {
      if (textLinkHover.value || noBg.value) {
        return ''
      } else if (hollow.value) {
        if (subtle.value) {
          return 'tw-border-dark dark:tw-border-light'
        } else if (secondary.value) {
          return _checkStatus('tw-bg-theme-2 tw-text-theme tw-border-primary')
        } else {
          if (success.value) return 'tw-border-success'
          if (danger.value) return 'tw-border-danger'
          if (warning.value) return 'tw-border-warning'
          else return 'tw-border-primary'
        }
      } else {
        if (primary.value) {
          if (textLink.value) return 'tw-text-primary'
          else return _checkStatus('tw-bg-primary tw-text-primary-text')
        } else if (success.value) {
          if (textLink.value) return 'tw-text-success'
          else return _checkStatus('tw-bg-success tw-text-success-text')
        } else if (info.value) {
          if (textLink.value) return 'tw-text-info'
          else return _checkStatus('tw-bg-info tw-text-info-text')
        } else if (warning.value) {
          if (textLink.value) return 'tw-text-warning'
          else return _checkStatus('tw-bg-warning tw-text-warning-text')
        } else if (danger.value) {
          if (textLink.value) return 'tw-text-danger'
          else return _checkStatus('tw-bg-danger tw-text-danger-text')
        } else if (secondary.value) {
          if (textLink.value) return ''
          else return _checkStatus('tw-bg-primary tw-text-primary-text')
        } else if (subtle.value) {
          return ''
        } else {
          if (textLink.value) return ''
          else return _checkStatus('tw-bg-input')
        }
      }
    })

    const buttonTypeHover = computed(() => {
      if (disabled.value || hasStatus.value || noBg.value) {
        return ''
      } else {
        if (primary.value) {
          if (textLink.value)
            return 'hover:tw-text-primary-hover focus:tw-text-primary-hover'
          else if (textLinkHover.value)
            return 'hover:tw-text-primary-hover focus:tw-text-primary-hover'
          else if (hollow.value)
            return 'hover:tw-bg-theme-2 focus:tw-bg-theme-2 group-hover:tw-bg-theme-2 group-focus:tw-bg-theme-2'
          else
            return 'hover:tw-bg-primary-hover focus:tw-bg-primary-hover group-hover:tw-bg-primary-hover group-focus:tw-bg-primary-hover'
        } else if (secondary.value || hollow.value) {
          if (textLink.value || textLinkHover.value)
            return 'hover:tw-text-light-2 dark:hover:tw-text-light-2 focus:tw-text-light-2 dark:focus:tw-text-light-2'
          else
            return 'tw-bg-theme-3--hover hover:tw-text-primary dark:hover:tw-text-primary focus:tw-text-primary dark:focus:tw-text-primary'
        } else if (success.value) {
          if (textLink.value)
            return 'hover:tw-text-success-hover focus:tw-text-success-hover'
          else if (textLinkHover.value)
            return 'hover:tw-text-success-hover focus:tw-text-success-hover'
          else
            return 'hover:tw-bg-success-hover focus:tw-bg-success-hover group-hover:tw-bg-success-hover group-focus:tw-bg-success-hover'
        } else if (info.value) {
          if (textLink.value)
            return 'hover:tw-text-info-hover focus:tw-text-info-hover'
          else if (textLinkHover.value)
            return 'hover:tw-text-info-hover focus:tw-text-info-hover'
          else
            return 'hover:tw-bg-info-hover focus:tw-bg-info-hover group-hover:tw-bg-info-hover group-focus:tw-bg-info-hover'
        } else if (warning.value) {
          if (textLink.value)
            return 'hover:tw-text-warning-hover focus:tw-text-warning-hover'
          else if (textLinkHover.value)
            return 'hover:tw-text-warning-hover focus:tw-text-warning-hover'
          else
            return 'hover:tw-bg-warning-hover focus:tw-bg-warning-hover group-hover:tw-bg-warning-hover group-focus:tw-bg-warning-hover'
        } else if (danger.value) {
          if (textLink.value)
            return 'hover:tw-text-danger-hover focus:tw-text-danger-hover'
          else if (textLinkHover.value)
            return 'hover:tw-text-danger-hover focus:tw-text-danger-hover '
          else
            return 'hover:tw-bg-danger-hover focus:tw-bg-danger-hover group-hover:tw-bg-danger-hover group-focus:tw-bg-danger-hover'
        } else if (subtle.value) {
          return 'hover:tw-text-primary-hover dark:hover:tw-text-primary-hover focus:tw-text-primary-hover dark:focus:tw-text-primary-hover'
        } else {
          if (textLink.value || textLinkHover.value)
            return 'hover:tw-text-primary-hover focus:tw-text-primary-hover'
          else return 'tw-bg-theme-2--hover'
        }
      }
    })

    const buttonRadius = computed(() => {
      if (radius.value === 'none' || textLinkHover.value) {
        return ''
      } else if (radius.value === 'bottom') {
        return 'tw-global--border-radius-b'
      } else if (radius.value === 'full') {
        return 'tw-rounded-full'
      } else if (radius.value === 'default') {
        return 'tw-global--border-radius'
      } else {
        return 'tw-global--border-radius'
      }
    })

    const buttonBorder = computed(() => {
      return hollow.value ? 'tw-border-2' : 'tw-border-none'
    })

    const buttonUppercase = computed(() => {
      if (uppercase.value) {
        return 'tw-uppercase'
      }
      return ''
    })

    const buttonPadding = computed(() => {
      if (autoWidth.value) {
        return 'tw-px-3'
      }
      return ''
    })

    const textAnimation = computed(() => {
      if (icon.value) {
        return ''
      }
      return hasStatus.value ? 'tw-translate-x-px' : 'tw-translate-x-0'
    })

    function _checkStatus(currentBg) {
      if (hasStatus.value) {
        if (isLoading.value) {
          return currentBg
        } else if (isSuccess.value) {
          return 'tw-bg-success tw-text-success-text'
        } else if (isError.value) {
          return 'tw-bg-danger tw-text-danger-text'
        }
      }
      return currentBg
    }

    function onClick() {
      if (errors.value && !isObjEmpty(errors.value)) {
        if (Object.values(errors.value).some((errArray) => errArray.length)) {
          updateStatus(statusType.ERROR)
        }
      }
    }

    return {
      buttonTopLevelStyles,
      buttonDisabledBg,
      buttonUppercase,
      buttonTypeHover,
      buttonTextSize,
      isTextLinkItem,
      textAnimation,
      buttonPadding,
      buttonCursor,
      buttonBorder,
      buttonRadius,
      buttonHeight,
      buttonWidth,
      isDisabled,
      buttonType,
      thisStatus,
      hasStatus,
      inputId,
      onClick,
    }
  },
}
</script>
