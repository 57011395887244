// All keys referenced for in-memory cache
export const KEYS_ADMIN_USERS = 'admin-users'
export const KEYS_DISCUSSION = 'discussion'
export const KEYS_GAMES = 'games'
export const KEYS_GUIDES = 'guide'
export const KEYS_MESSAGE_INBOX = 'message-inbox'
export const KEYS_MESSAGE_THREAD = 'message-thread'
export const KEYS_MODS = 'mods'
export const KEYS_NOTIFICATIONS = 'notifications'
export const KEYS_TAG_FILTER = 'tag-filter'
export const KEYS_USERS = 'users'
export const KEYS_OPEN_REPORTS = 'reports-open'
export const KEYS_RESOLVED_REPORTS = 'reports-resolved'
export const KEYS_COGS_GAME = 'cogs-game'
export const KEYS_COGS_MOD = 'cogs-mod'
export const KEYS_COGS_GUIDE = 'cogs-guide'
export const KEYS_MEDIA_LIBRARY = 'media-library'
