import { gameStore } from '@stores'
import { computed } from 'vue'

export default function () {
  const { getPublicGame } = gameStore()

  function getTheme(gameNameId) {
    const game = getPublicGame(gameNameId)
    return computed(() => {
      // ensures theme is not dropped when
      // redirecting from new game creation
      return game.value?.theme
    })
  }

  return {
    getTheme,
  }
}
