import { LS_ACCESSTOKEN, LS_USER } from '@config/localstorage.js'
import userSerializer from '@serializers/userSerializer.js'
import { subscriptionStore, ratingStore } from '@stores'
import * as sdkInterface from '@plugins/sdkInterface.js'
import { useLocalStorage } from '@vueuse/core'
import { computed } from 'vue'

const DEFAULT_LANG = 'en'
const accessTokenState = useLocalStorage(LS_ACCESSTOKEN, null)
const userState = useLocalStorage(LS_USER, null)

export default function useAuth() {
  const { subscriptions, clearSubscriptions } = subscriptionStore()
  const { clearRatings } = ratingStore()

  const accessToken = computed(() => JSON.parse(accessTokenState.value))
  const user = computed(() => JSON.parse(userState.value))

  const isLoggedIn = computed(() =>
    user.value?.id && accessToken.value ? true : false
  )

  function setUser(user) {
    if (!user) {
      throw new Error()
    }
    userState.value = JSON.stringify(userSerializer.transform(user))
    sdkInterface.setUserId(user.id)
  }

  function setToken(accessToken) {
    if (!accessToken) {
      throw new Error()
    }
    accessTokenState.value = JSON.stringify(accessToken)
  }

  function clearUser() {
    clearSubscriptions()
    clearRatings()
    userState.value = JSON.stringify({})
    accessTokenState.value = null
    sdkInterface.setAuthToken('')
  }

  function getUserId() {
    return user.value?.id
  }

  function getUserLanguage() {
    return user.value?.language || DEFAULT_LANG
  }

  return {
    getUserLanguage,
    subscriptions,
    accessToken,
    isLoggedIn,
    getUserId,
    clearUser,
    setToken,
    setUser,
    user,
  }
}
