<template>
  <loading-radio-group
    v-if="inputLoading"
    :background="background"
    :options="options"
    :alt-bg="altBg"
  ></loading-radio-group>
  <div
    v-else
    class="tw-flex"
    :class="[wrapClass, optionList ? 'tw-flex-row' : 'tw-flex-col']"
  >
    <base-input-label v-if="required" :required="required"></base-input-label>
    <label
      v-for="(option, index) in options"
      :key="index"
      class="tw-group tw-global--border-radius tw-flex tw-items-center tw-button-transition tw-relative"
      :class="[
        inputBgStyles,
        disabled ? 'tw-cursor-default tw-opacity-75' : 'tw-cursor-pointer',
        small ? 'tw-text-sm' : 'tw-input--text-size',
        background ? 'tw-input--px tw-input--min-height' : '',
        {
          'tw-flex tw-items-center tw-justify-center tw-input--height tw-w-full tw-font-bold tw-input--px':
            optionList,
          'tw-capitalize': capsCase,
          'tw-input--height': optionList && !small,
          'tw-input--height-small': optionList && small,
        },
        { 'tw-radio-gap last:tw-mb-0': !optionList },
        errorStyles,
      ]"
    >
      <!-- hidden input is used for tabbing / focus -->
      <input
        class="tw-absolute tw-opacity-0 tw-h-0 tw-w-0"
        :checked="modelValue === options[index]"
        type="radio"
        :name="`${inputId}RadioGroup`"
        :disabled="readonly"
        :tabindex="disabled ? '-1' : '0'"
        @click="updateCheckbox(index)"
      />

      <span
        class="tw-flex tw-items-center tw-justify-center tw-shrink-0 tw-button-transition"
        :class="[
          isChecked(index),
          hasHover(index),
          optionList
            ? 'tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-global--border-radius tw-z-0'
            : 'tw-h-3 tw-w-3 tw-rounded-full tw-mr-2',
        ]"
      >
        <span v-if="optionList" class="tw-z-1">{{
          capsCase ? option.toLowerCase() : option
        }}</span>
      </span>

      <span v-if="!optionList" class="tw-z-1">{{
        capsCase ? option.toLowerCase() : option
      }}</span>

      <span
        v-if="appendMap?.[option]"
        class="tw-ml-auto tw-pl-1 tw-text-right tw-opacity-50"
      >
        {{ appendMap[option].toLocaleString() }}
      </span>
    </label>

    <div
      v-if="hasErrors || hint"
      class="tw-flex tw-flex-col tw-hint-gap tw-space-y-1"
    >
      <base-input-errors :errors="errors" align="left" />

      <base-input-hint v-if="hint">
        {{ hint }}
      </base-input-hint>
    </div>
  </div>
</template>

<script>
import LoadingRadioGroup from '@components/Loading/LoadingRadioGroup.vue'
import { genHtmlId, isObjEmpty } from '@helpers/utils.js'
import { toRefs, computed, inject } from 'vue'
export default {
  components: {
    LoadingRadioGroup,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    appendMap: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    background: {
      type: Boolean,
      default: false,
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    wrapClass: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    capsCase: {
      type: Boolean,
      default: false,
    },
    optionList: {
      type: Boolean,
      default: false,
    },
    altBg: {
      type: Boolean,
      default: false,
    },
    sidebar: {
      type: Boolean,
      default: false,
    },
    hoverHighlight: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const {
      hoverHighlight,
      background,
      modelValue,
      disabled,
      options,
      sidebar,
      errors,
      altBg,
      id,
    } = toRefs(props)

    const inputId = id.value ? id.value : genHtmlId()
    const readonly = inject('saving', false)

    const hasErrors = computed(() => !isObjEmpty(errors.value))

    const errorStyles = computed(() => {
      if (hasErrors.value) {
        return background.value
          ? 'tw-border-danger tw-border-2'
          : 'tw-text-danger'
      }
      return ''
    })

    const inputBgStyles = computed(() => {
      if (disabled.value) {
        return background.value ? 'tw-bg-theme-1' : ''
      }
      return background.value
        ? 'tw-bg-input-hover tw-input--focus-within'
        : 'tw-opacity-90 hover:tw-opacity-100 focus-within:tw-text-primary'
    })

    function hasHover(index) {
      if (
        disabled.value ||
        modelValue.value === options.value[index] ||
        !hoverHighlight.value
      ) {
        return ''
      }

      if (altBg.value) {
        return background.value ? '' : 'tw-bg-theme--group-hover'
      } else {
        return background.value ? '' : 'tw-bg-theme-2--group-hover'
      }
    }

    function isChecked(index) {
      if (modelValue.value === options.value[index]) {
        return 'tw-bg-primary tw-text-primary-text'
      } else if (altBg.value) {
        return background.value
          ? ''
          : `${
              sidebar.value ? 'tw-mobile-grey' : 'tw-bg-theme'
            } tw-opacity-40 group-hover:tw-opacity-100`
      } else {
        return background.value ? 'tw-bg-theme' : 'tw-bg-theme-1'
      }
    }

    function updateCheckbox(value) {
      if (!disabled.value) {
        emit('input', value)
      }
    }

    return {
      updateCheckbox,
      inputBgStyles,
      errorStyles,
      hasErrors,
      isChecked,
      hasHover,
      readonly,
      inputId,
    }
  },
}
</script>
