import { USER_PRIVACY_OPTIONS } from '@config/options.js'
import Serializer from './Serializer.js'

const userOptions = {
  privacy_options: USER_PRIVACY_OPTIONS,
}

const userSerializer = new Serializer({
  options: userOptions,
})

export default userSerializer
