<template>
  <div class="tw-w-full tw-relative">
    <div :class="heightClass"></div>
    <base-divider v-if="baseDivider"></base-divider>
    <div v-if="baseDivider" :class="heightClass"></div>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue'
export default {
  props: {
    height: {
      type: String,
      default: '1',
    },
    baseDivider: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { baseDivider, height } = toRefs(props)

    const heightClass = computed(() => {
      if (baseDivider.value) {
        return `tw-h-${height.value / 2}`
      } else {
        return `tw-h-${height.value}`
      }
    })

    return {
      heightClass,
    }
  },
}
</script>
