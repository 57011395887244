<template>
  <base-button
    subtle
    basic-btn
    text-link
    :primary="isActive"
    class="tw-opacity-50 hover:tw-opacity-100 focus:tw-opacity-100"
    :class="{ 'tw-opacity-100': isActive }"
    title="Learn More"
    icon="question-circle"
    @click="toggleTip(tip)"
  />
</template>
<script>
import { toRefs, computed } from 'vue'
import { useTip } from '@composables'

export default {
  props: {
    tip: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { tip } = toRefs(props)
    const { activeTip, toggleTip } = useTip()

    const isActive = computed(() => tip.value === activeTip.value)

    return {
      activeTip,
      toggleTip,
      isActive,
    }
  },
}
</script>
