import { SCROLL_TO_ID } from '@config/htmlIDs.js'
import { ref, computed } from 'vue'

const menuComponent = ref(null)
const showDropdown = ref(false)
const mobileMenu = ref(false)
const overlay = ref(false)

const MENU_COMPONENTS = {
  PROFILE_DROPDOWN: 'ProfileDropdown',
  NOTIFICATION_DROPDOWN: 'NotificationDropdown',
  MESSAGE_LIST: 'MessageList',
}

export default function () {
  const showMenu = computed(() => showDropdown.value)
  const showMobMenu = computed(() => mobileMenu.value)
  const menu = computed(() => menuComponent.value)
  const showOverlay = computed(() => overlay.value)

  function changeMenu(component) {
    menuComponent.value = component
  }

  function _openDropdown() {
    showDropdown.value = true
    _showOverlayComp()
  }

  function _closeDropdown() {
    showDropdown.value = false
    _hideOverlayComp()
  }

  function _openMobMenu() {
    mobileMenu.value = true
    _showOverlayComp()
  }

  function _toggleMobMenu() {
    mobileMenu.value = !mobileMenu.value
  }

  function closeMobMenu(hideOverlay = true) {
    mobileMenu.value = false
    if (hideOverlay) {
      _hideOverlayComp()
    }
  }

  // overlay
  function _showOverlayComp() {
    overlay.value = true
    _addClass()
  }

  function _hideOverlayComp() {
    overlay.value = false
    _removeClass()
  }

  function _toggleOverlay() {
    overlay.value = !overlay.value
  }

  // misc
  function _addClass() {
    const overflowTarget = document.getElementById(SCROLL_TO_ID)
    if (overflowTarget) {
      overflowTarget.classList.add('tw-overflow-hidden')
    }
  }

  function _removeClass() {
    const overflowTarget = document.getElementById(SCROLL_TO_ID)
    if (overflowTarget) {
      overflowTarget.classList.remove('tw-overflow-hidden')
    }
  }

  function closeAllMenus() {
    closeMobMenu()
    _closeDropdown()
  }

  // main menu
  function mobMenuToggle() {
    if (showMenu.value) {
      _closeDropdown()
      _openMobMenu()
    } else {
      _toggleMobMenu()
      _toggleOverlay()
    }
  }

  // dropdown
  function showUserDropdown() {
    closeMobMenu(false)
    if (!showDropdown.value) {
      _openDropdown()
    } else if (menuComponent.value === MENU_COMPONENTS.PROFILE_DROPDOWN) {
      _closeDropdown()
    }
    changeMenu(MENU_COMPONENTS.PROFILE_DROPDOWN)
  }

  function showNotificationDropdown() {
    closeMobMenu(false)
    if (!showDropdown.value) {
      _openDropdown()
    } else if (menuComponent.value === MENU_COMPONENTS.NOTIFICATION_DROPDOWN) {
      _closeDropdown()
    }
    changeMenu(MENU_COMPONENTS.NOTIFICATION_DROPDOWN)
  }

  function showMessagesDropdown() {
    closeMobMenu(false)
    if (!showDropdown.value) {
      _openDropdown()
    } else if (menuComponent.value === MENU_COMPONENTS.MESSAGE_LIST) {
      _closeDropdown()
    }
    changeMenu(MENU_COMPONENTS.MESSAGE_LIST)
  }

  return {
    showNotificationDropdown,
    showMessagesDropdown,
    showUserDropdown,
    MENU_COMPONENTS,
    menuComponent,
    mobMenuToggle,
    closeAllMenus,
    closeMobMenu,
    showOverlay,
    showMobMenu,
    showMenu,
    menu,
  }
}
