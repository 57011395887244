export default function format(input) {
  // Extract mentions
  const mentionRegex =
    /<a href="https:\/\/(mod\.io|beta\.mod\.io|web-staging\.moddemo\.io|mod\.io\.dev\.internal)\/(members|games|u|g)\/[\w_-]+(\/(m|mods|r|guides)\/[\w_-]+)?">[\w_-]+<\/a>/g
  const mentions = input.match(mentionRegex)
  let i = 0
  input = input.replace(mentionRegex, () => `@${i++}`)

  input = input
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(
      /(^|[\s>]+)([\w]+?:\/\/[\w#$%&~/.\-;:=,?!@[\]+]*)/gi,
      '$1<a target="_blank" rel="noreferrer noopener" href="$2">$2</a>'
    )
    .replace(
      /(^|[\s>]+)((www|ftp)\.[\w#$%&~/.\-;:=,?!@[\]+]*)/gi,
      '$1<a target="_blank" rel="noreferrer noopener" href="https://$2">$2</a>'
    )
    .replace(
      /(^|[\s>]+)([a-z0-9\-_.]+)@([^[,<\s]+)/gi,
      '$1<a href="mailto:$2@$3">$2@$3</a>'
    )

  // mod.io links
  input = input.replace(
    /(?:>)https:\/\/(?:mod\.io|beta\.mod\.io|web-staging\.moddemo\.io|mod\.io\.dev\.internal)\/(?:members|games|u|g)\/([\w_-]+(\/(m|r)\/[\w_-]+)?)\/?(<)/g,
    (_, p1) => `>@${p1.split('/').slice(-1)}<`
  )

  // Youtube embed
  input = input.replace(
    /<a target="_blank" rel="noreferrer noopener" href="https?:\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)[^"]*">[^<]+<\/a>/gi,
    '<iframe width="560" height="315" src="https://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe>'
  )

  // Add mentions back in
  input = input.replace(/@\d+/g, (match) => {
    const index = Number(match.slice(1))
    if (mentions?.[index]) {
      return mentions[index].replace(/>([\w_-]+)</, '>@$1<')
    }
    return match
  })

  return input
}

export function addBlankTargets(text) {
  return ('' + text).replace(
    /<a\s+href=/gi,
    '<a target="_blank" rel="noreferrer noopener" href='
  )
}

export function stripLinks(text) {
  return ('' + text).replace(/(<a [^>]*>)(.*?)(<\/a>)/gi, '<u>$2</u>')
}
