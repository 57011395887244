import { getCurrentInstance, computed, isRef, watch, ref } from 'vue'
import { PAGINATION_DEFAULT_PAGE } from '@config/options.js'
import { API_RESULT_LIMIT } from '@config'
import {
  getQueryFromPath,
  isNumber,
  isArray,
  isEqual,
  clone,
} from '@helpers/utils.js'
import {
  usePagination,
  useSearch,
  useFilter,
  useRoute,
  useQuery,
  useSort,
} from '@composables'

let lastBrowseState = {}

export default function ({
  id,
  fetch,
  items,
  total,
  simple,
  sortOptions,
  initialSort,
  infinite = false,
  limit,
  refresh,
  searchKey,
} = {}) {
  const previousFilters = ref([])

  const { emit } = getCurrentInstance()

  const events = ['fetch:paginate-next', 'fetch:paginate-prev', 'fetch:query']

  if (!id || !isRef(items) || !isRef(total) || !sortOptions || !isRef(limit)) {
    throw new TypeError()
  }

  const { routeQuery: query, replace } = useRoute()
  const validStates = ['invalid', 'prev', 'next']

  const rangeRef = ref({ start: 0, end: 0 })
  const stateRef = ref(null)
  const itemsRef = ref([])
  const inMemTotal = ref(null)

  const itemList = computed(() => itemsRef.value || [])
  const state = computed(() => stateRef.value)
  const range = computed(() => rangeRef.value)

  const allResultsRetrieved = computed(
    () => isArray(items.value?.data) && items.value.data.length === total.value
  )

  const canShowMore = computed(
    () => total.value > Math.max(itemList.value.length, pagination.value.limit)
  )

  const { queryObject, getStrippedQuery } = useQuery(id)
  const { setSort, sort, getDefaultSort, inMemorySort } = useSort(
    id,
    query.value._sort,
    sortOptions
  )

  const { activeFilters, inMemoryFilter } = useFilter(id)
  const { submittedBy, inMemorySearch } = useSearch(
    id,
    query.value._q,
    query.value.submitted_by
  )

  const {
    inMemoryPagination,
    resetPagination,
    pagination,
    calculateOffset,
    setPagination,
    getLimit,
  } = usePagination(id, {
    limit: limit.value,
    offset: 0,
    page: 1,
    simple,
  })

  if (!sort.value) {
    const defaultSort = getDefaultSort()

    if (defaultSort) {
      updateSort({ ...defaultSort }, true)
    }
  }

  watch(itemsRef, () => {
    setValid()
    if (refresh) {
      updateUrlQuery()
    }
  })

  watch(submittedBy, () => updateTable('search'))

  watch(
    [items, total],
    ([_items, _total], [, _oldTotal]) => {
      if (!isArray(_items?.data)) return

      _setRange(_items.data.length)

      // TODO:  Being used only by **game** browse to resolve the inconsistency with sorting by downloads, popular
      // and any cron dependent fields. Can remove once API provides consistent sorting option by popularity.
      if (initialSort?.value) {
        updateTable('sort', { ...getDefaultSort(), dropdown: true })
        return
      }

      if (
        lastBrowseState.id === id &&
        !_oldTotal &&
        getLimit.value < lastBrowseState.limit
      ) {
        setPagination({ limit: lastBrowseState.limit })
      }

      inMemTotal.value = simple ? _items.data.length : 0
      if (simple) {
        setPagination({ total: _items.data.length })
      } else if (isNumber(_total) && _total !== _oldTotal) {
        setPagination({ total: _total, page: PAGINATION_DEFAULT_PAGE })
      }

      if (isValid()) {
        updateTable()
      } else {
        // If invalid then we must overwrite items and start at offset 0
        // pagination and sort will be correctly configured before the fetch event
        _updateItems(0)
      }
    },
    { immediate: true }
  )

  if (simple) {
    watch(limit, (_limit) => {
      setPagination({ limit: _limit })
    })

    watch(
      () => items.value?.nextPageUrl,
      (_nextPageUrl) => {
        setPagination({ hasNextPage: !!_nextPageUrl })
      }
    )
  }

  function updateTable(type, data) {
    if (type === 'sort') {
      updateSort(data)
    }

    // Fetch for items if type is search and there was a
    // previous search. Cannot do inmemory in this case
    // because result_total might not contain all results
    if (
      type === 'search' &&
      (items.value?.query?._q || items.value?.query?.submitted_by)
    ) {
      setInvalid()
      if (!fetch.value) {
        inMemoryUpdate()
        return
      }
    }

    const _query = !simple ? getQuery() : {}
    // If all results are available operate inmemory
    if (
      (allResultsRetrieved.value && isValid()) ||
      (isEqual(
        { ..._query, _offset: 0 },
        { ...items.value?.query, _offset: 0 }
      ) &&
        type !== 'pagination')
    ) {
      inMemoryUpdate()
      // set the correct pagination
      if (type === 'filter' && activeFilters.value.length) {
        setPagination({ total: itemList.value.length })
      }
      return
    }

    // If execution reach this point inmemory update was not possible
    // we will emit query if type exist and is not pagination
    if (type && type !== 'pagination') {
      if (fetch.value) {
        if (type !== 'sort' || infinite) {
          resetPagination()
          inMemTotal.value = 0
        }
        setInvalid()
        // Can't reuse _query here
        emit('fetch:query', getQuery())
      } else {
        emit('fetch:query', queryObject.value)
      }

      // clear items to remove from infinte ui when fetching query
      if (infinite) {
        itemsRef.value = []
      }
      if (!initialSort?.value) {
        return
      }
    }

    const currentOffset = calculateOffset()
    // Don't exceed end of result total
    let endOffset = currentOffset + getLimit.value
    endOffset = (simple ? endOffset : Math.min(endOffset, total.value)) - 1

    if (type === 'pagination') {
      const hasPassEnd = endOffset > range.value.end
      const hasPassStart = currentOffset < range.value.start
      if (hasPassEnd) {
        if (!simple || items.value.nextPageUrl) {
          setState('next')
          emit('fetch:paginate-next', getQuery('next'))
        }
        return
      } else if (hasPassStart) {
        setState('prev')
        emit('fetch:paginate-prev', getQuery('prev'))
        return
      } else if (data === 'limit') {
        setInvalid()
        emit('fetch:query', getQuery())
        return
      }
      setInvalid()
      // pagination can fall through and do inmemory paginate
    }

    let start = currentOffset - (isInitialFetch() ? 1 : range.value.start)

    _updateItems(start)
  }

  function updateSort(_sort, defaultDirection = false) {
    if (_sort.dropdown) {
      _setSort(_sort, _sort.direction)
      return
    }

    _sort.key === sort.value?.sortBy && !defaultDirection
      ? _setSort(_sort, _reverseOrder())
      : _setSort(_sort, _sort.direction)
  }

  function _reverseOrder() {
    return sort.value?.order === 'asc' ? 'desc' : 'asc'
  }

  function _setSort(_sort, order) {
    setSort({
      ..._sort,
      sortBy: _sort.key,
      order,
    })
  }

  function setState(state) {
    if (validStates.includes(state)) {
      stateRef.value = state
    }
  }

  function updateUrlQuery() {
    replace({
      query: getStrippedQuery(getQuery(), getDefaultSort()),
    })
  }

  function isInitialFetch() {
    return range.value.end === 0
  }

  function setInvalid() {
    stateRef.value = 'invalid'
  }

  function isValid() {
    return stateRef.value !== 'invalid'
  }

  function setValid() {
    stateRef.value = null
  }

  function hasTagFromSelectedDropdown() {
    const newFilter = newSelectedFilter()

    return (
      newFilter?.type === 'dropdown' &&
      previousFilters.value.some((f) => f.id === newFilter.id)
    )
  }

  function newSelectedFilter() {
    let newFilter
    activeFilters.value.forEach((f) => {
      if (
        previousFilters.value.find((p) => {
          return p.id === f.id && p.tag === f.tag && p.type === f.type
        })
      ) {
        return
      }

      newFilter = f
    })

    return newFilter
  }

  // Determines if a fetch is required
  function handleFilterUpdate(filters) {
    let newFilters = []
    filters.forEach((t) => {
      t.selected.forEach((tagName) =>
        newFilters.push({
          id: t.id,
          filter: t.name,
          tag: tagName,
          type: t.type,
        })
      )
    })

    if (
      hasTagFromSelectedDropdown() ||
      // initial load with filters makes cache invalid
      (previousFilters.value.length === 0 &&
        activeFilters.value.length !== newFilters.length) ||
      // filters deselected makes cache invalid
      previousFilters.value.length > newFilters.length
    ) {
      setInvalid()
    }

    previousFilters.value = newFilters
  }

  // Range is indexed at 0
  function _setRange(count) {
    let start = range.value.start
    let end = range.value.end

    if (!isValid()) {
      start = Math.max(0, queryObject.value._offset - 1)
      end = start ? start + count : start + count - 1
      if (queryObject.value._offset !== 0) {
        start++
        end++
      }
    } else if (state.value === 'next') {
      end = range.value.end + (simple ? limit.value : API_RESULT_LIMIT)
    } else if (state.value === 'prev') {
      start = Math.max(0, start - (simple ? limit.value : API_RESULT_LIMIT))
    } else {
      end = count - 1
    }

    rangeRef.value = {
      start,
      end: simple ? end : Math.min(end, total.value - 1),
    }
  }

  // TODO: possible to fetch limit num records from the end
  // to pre fetch previous items and save in memory
  function getQuery(direction) {
    const query = { ...queryObject.value, _limit: API_RESULT_LIMIT }
    // if start offset is less than limit and paginating to prev
    // we can set offset to 0 and pre fetch to the start of the result set
    if (simple) {
      return getQueryFromPath(items.value.nextPageUrl)
    } else if (direction === 'prev') {
      if (range.value.start <= API_RESULT_LIMIT) {
        query._limit = range.value.start
        query._offset = 0
      } else {
        query._offset = range.value.start - API_RESULT_LIMIT
      }
    } else if (isValid()) {
      const offset =
        range.value?.end === total.value
          ? calculateOffset()
          : !range.value.start && !isInitialFetch()
          ? range.value.end + 1
          : range.value.end
      query._offset = Math.max(0, offset)
    }

    const strip = ['tags-in', 'platforms', '_q', 'submitted_by']
    for (const param of strip) {
      if (!query[param]) {
        delete query[param]
      }
    }

    return query
  }

  function inMemoryUpdate() {
    const _items = clone(items.value.data)
    let data = inMemorySort({ data: _items }, false)

    data = inMemoryFilter(data, false)
    data = inMemorySearch(data, searchKey)
    inMemTotal.value = data.length
    if (infinite) {
      itemsRef.value = data.slice(
        0,
        Math.min(total.value, pagination.value.limit)
      )
    } else {
      data = inMemoryPagination(data, false)
      itemsRef.value = data
    }
  }

  function _updateItems(start) {
    const _start = infinite ? 0 : Math.max(0, start)
    itemsRef.value =
      items.value?.data?.slice(_start, _start + getLimit.value) || []
    lastBrowseState = {
      id,
      limit: getLimit.value,
    }
  }

  function handleScroll() {
    setPagination({ limit: pagination.value.limit + limit.value })
    updateTable('pagination', 'next')
  }

  return {
    allResultsRetrieved,
    handleFilterUpdate,
    handleScroll,
    canShowMore,
    updateTable,
    setInvalid,
    inMemTotal,
    setValid,
    itemList,
    setState,
    events,
    range,
    state,
    sort,
  }
}
