export const AGREEMENT_TYPE_TOU = 1
export const AGREEMENT_TYPE_PRIVACY = 2
export const AGREEMENT_TYPE_DEVELOPER = 3
export const AGREEMENT_TYPE_API = 4
export const AGREEMENT_TYPE_PAYMENT = 5
export const AGREEMENT_TYPE_AUP = 6

export const STATUS_NOT_ACCEPTED = 0
export const STATUS_ACCEPTED = 1
export const STATUS_DELETED = 3
export const STATUS_BANNED = 4

export const VISIBLE_HIDDEN = 0
export const VISIBLE_PUBLIC = 1

export const CURATION_NONE = 0
export const CURATION_PAID = 1
export const CURATION_FULL = 2

export const TEAM_MEMBER_LEVEL_MODERATOR = 1
export const TEAM_MEMBER_LEVEL_MANAGER = 4
export const TEAM_MEMBER_LEVEL_ADMINISTRATOR = 8

export const MEMBER_LEVEL_GUEST = 0
export const MEMBER_LEVEL_MEMBER = 1
export const MEMBER_LEVEL_CONTRIBUTOR = 2
export const MEMBER_LEVEL_MANAGER = 4
export const MEMBER_LEVEL_LEADER = 8

export const GAME_COMMUNITY_ALLOW_COMMENTS = 1
export const GAME_COMMUNITY_ALLOW_GUIDES = 2

export const MOD_COMMUNITY_ALLOW_COMMENTS = 1

export const GAME_PRESENTATION_GRID = 0
export const GAME_PRESENTATION_TABLE = 1

export const GAME_MOD_SUBMISSION_TOOLS_ONLY = 0
export const GAME_MOD_SUBMISSION_TOOLS_AND_WEBSITE = 1

export const PAGINATION_DEFAULT_PAGE = 1
export const PAGINATION_DEFAULT_TOTAL = 0
export const PAGINATION_DEFAULT_LIMIT = 30

export const RATINGS_LIKE = 1
export const RATINGS_DISLIKE = -1
export const RATINGS_UNDECIDED = 0
export const RATINGS_FEW = 5
export const RATINGS_MANY = 50

export const API_THIRD_PARTY_ACCESS = 1
export const API_DIRECT_DOWNLOAD = 2

export const FILE_VIRUS_STATUS_NOT_SCANNED = 0
export const FILE_VIRUS_STATUS_SCAN_COMPLETE = 1
export const FILE_VIRUS_STATUS_SCAN_IN_PROGRESS = 2
export const FILE_VIRUS_STATUS_ERROR_FILE_TOO_LARGE = 3
export const FILE_VIRUS_STATUS_ERROR_FILE_NOT_FOUND = 4
export const FILE_VIRUS_STATUS_ERROR_SCANNING = 5

export const AGREEMENT_TYPE_OPTIONS = [
  { value: AGREEMENT_TYPE_TOU, text: 'Terms of Use', short: 'Terms' },
  { value: AGREEMENT_TYPE_PRIVACY, text: 'Privacy Policy', short: 'Privacy' },
  {
    value: AGREEMENT_TYPE_DEVELOPER,
    text: 'Developer Terms',
    short: 'Developer',
  },
  { value: AGREEMENT_TYPE_API, text: 'API Access Terms', short: 'API' },
  { value: AGREEMENT_TYPE_PAYMENT, text: 'Payment Terms', short: 'Payment' },
  { value: AGREEMENT_TYPE_AUP, text: 'Acceptable Use Policy', short: 'AUP' },
]

export const STATUS_OPTIONS = [
  { value: STATUS_NOT_ACCEPTED, text: 'Pending' },
  { value: STATUS_ACCEPTED, text: 'Accepted' },
  { value: STATUS_DELETED, text: 'Deleted' },
  { value: STATUS_BANNED, text: 'Banned' },
]

export const TEAM_MEMBER_LEVELS_OPTIONS = [
  { value: TEAM_MEMBER_LEVEL_MODERATOR, text: 'Moderator' },
  { value: TEAM_MEMBER_LEVEL_MANAGER, text: 'Manager' },
  { value: TEAM_MEMBER_LEVEL_ADMINISTRATOR, text: 'Administrator' },
]

export const STAFF_MEMBER_LEVELS_OPTIONS = [
  { value: MEMBER_LEVEL_GUEST, text: 'Guest' },
  { value: MEMBER_LEVEL_MEMBER, text: 'Member' },
  { value: MEMBER_LEVEL_CONTRIBUTOR, text: 'Contributor' },
  { value: MEMBER_LEVEL_MANAGER, text: 'Manager' },
  { value: MEMBER_LEVEL_LEADER, text: 'Leader' },
]

// export const STAFF_MEMBER_DEPARTMENT_OPTIONS = [
//   { value: 'all', text: 'All staff' },
//   { value: 'managment', text: 'Managment' },
//   { value: 'webapi', text: 'Web and Api' },
//   { value: 'intergration', text: 'Intergration' },
//   { value: 'monetisation', text: 'Monetisation' },
//   { value: 'outreach', text: 'Outreach' },
//   { value: 'marketing', text: 'Marketing' },
// ]

export const USER_PRIVACY_EVERYONE = 0
export const USER_PRIVACY_SUBSCRIBERS = 1
export const USER_PRIVACY_NONE = 2
export const USER_PRIVACY_EVERYONE_NO_COMMENTS = 3

export const USER_PRIVACY_OPTIONS = [
  { value: USER_PRIVACY_EVERYONE, text: 'Everyone can see my profile' },
  {
    value: USER_PRIVACY_SUBSCRIBERS,
    text: 'Only subscribers can see my profile',
  },
  { value: USER_PRIVACY_NONE, text: 'Only I can see my profile' },
  {
    value: USER_PRIVACY_EVERYONE_NO_COMMENTS,
    text: 'Everyone can see my profile, but my comment history is hidden',
  },
]

export const GAME_UGC_NAME_OPTIONS = [
  { value: 'Mods', text: 'Mods' },
  { value: 'UGC', text: 'UGC' },
  { value: 'Levels', text: 'Levels' },
  { value: 'Other', text: 'Other' },
]

export const GAME_TAG_TYPE_OPTIONS = [
  { value: '', text: 'Select Type' },
  { value: 'dropdown', text: 'Dropdown' },
  { value: 'checkboxes', text: 'Checkboxes' },
]

export const GAME_PRESENTATION_OPTIONS = [
  { value: GAME_PRESENTATION_GRID, text: 'Grid' },
  { value: GAME_PRESENTATION_TABLE, text: 'Table' },
]

export const GAME_MOD_SUBMISSION_OPTIONS = [
  { value: GAME_MOD_SUBMISSION_TOOLS_ONLY, text: 'Tools only' },
  { value: GAME_MOD_SUBMISSION_TOOLS_AND_WEBSITE, text: 'Tools and Website' },
]

export const GAME_MOD_CURATION_OPTIONS = [
  { value: CURATION_NONE, text: 'No Curation' },
  { value: CURATION_FULL, text: 'Full Curation' },
]

export const REPORT_TYPE_DMCA = 1

export const REPORT_TYPE_OPTIONS = [
  // API 0=generic, however it should not be a selectable option on report
  { value: 0, text: '' },
  { value: REPORT_TYPE_DMCA, text: 'report_dmca' },
  { value: 2, text: 'report_not_working' },
  { value: 3, text: 'report_rude_content' },
  { value: 4, text: 'report_illegal_content' },
  { value: 5, text: 'report_stolen_content' },
  { value: 6, text: 'report_false_information' },
  { value: 7, text: 'report_other' },
]

// These values are consistent with the API
export const RESOURCE_GAMES = 'games'
export const RESOURCE_MODS = 'mods'
export const RESOURCE_GUIDES = 'articles'
export const RESOURCE_USERS = 'users'

export const RESOURCE_OPTIONS = {
  [RESOURCE_GAMES]: 'Game',
  [RESOURCE_MODS]: 'Mod',
  [RESOURCE_GUIDES]: 'Guide',
  [RESOURCE_USERS]: 'User',
}

export const RATINGS_TEXT_OPTIONS = {
  UNRATED: 'unrated',
  OVERWHELMINGLY_POSITIVE: 'overwhelmingly_positive',
  VERY_POSITIVE: 'very_positive',
  POSITIVE: 'positive',
  MOSTLY_POSITIVE: 'mostly_positive',
  MIXED: 'mixed',
  MOSTLY_NEGATIVE: 'mostly_negative',
  NEGATIVE: 'negative',
  VERY_NEGATIVE: 'very_negative',
  OVERWHELMINGLY_NEGATIVE: 'overwhelmingly_negative',
}

export const MATURITY_ALCOHOL = 1
export const MATURITY_DRUGS = 2
export const MATURITY_VIOLENCE = 4
export const MATURITY_EXPLICIT = 8

export const MOD_MATURITY_OPTIONS = [
  { value: MATURITY_ALCOHOL, text: 'Alcohol' },
  { value: MATURITY_DRUGS, text: 'Drugs' },
  { value: MATURITY_VIOLENCE, text: 'Violence' },
  { value: MATURITY_EXPLICIT, text: 'Explicit' },
]

export const GAME_MATURE_MODS_DENY = 0
export const GAME_MATURE_MODS_ALLOW = 1
export const GAME_ADULTS_ONLY = 2

export const GAME_MATURITY_OPTIONS = [
  { value: GAME_MATURE_MODS_DENY, text: 'Disallow mature content' },
  { value: GAME_MATURE_MODS_ALLOW, text: 'Allow mature content' },
  { value: GAME_ADULTS_ONLY, text: 'Mature audiences only' },
]

export const SCOPE_OPTIONS = [
  { value: 'read', text: 'Read' },
  { value: 'write', text: 'Write' },
  { value: 'web-rw', text: 'Read + Write' },
]

export const PAGINATION_LIMIT_OPTIONS = [
  { value: 30, text: '30/Page' },
  { value: 40, text: '40/Page' },
  { value: 50, text: '50/Page' },
]

export const DISCUSSION_TYPE_MODS = 'mods'
export const DISCUSSION_TYPE_GUIDES = 'guides'
export const DISCUSSION_TYPES = [DISCUSSION_TYPE_MODS, DISCUSSION_TYPE_GUIDES]
