<template>
  <loading-label v-if="inputLoading"></loading-label>

  <label
    v-else
    class="tw-w-full tw-flex tw-flex-wrap tw-justify-between sm:tw-items-end"
    :class="{ 'tw-label-gap': marginBottom }"
    :for="idFor"
  >
    <span
      class="tw-font-bold"
      :class="large ? 'tw-input--text-size' : 'tw-text-sm'"
    >
      {{ label }}
      <sup
        v-if="required"
        class="tw-relative tw-top-0 tw-input--text-size tw-text-danger"
      >
        *
      </sup>
    </span>
  </label>
</template>
<script>
import LoadingLabel from '@components/Loading/LoadingLabel.vue'
export default {
  components: {
    LoadingLabel,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    idFor: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    marginBottom: {
      type: Boolean,
      default: false,
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: [Object, String],
      default: null,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
