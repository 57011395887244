import { publicRoutes } from './allRoutes.js'
import { clone } from '@helpers/utils.js'

let routes

const allTransformations = [
  _parentMetaFieldToChildren('error'),
  _parentMetaFieldToChildren('seo'),
  _childrenToMeta,
]

// TODO: make transforms operate on a single route to avoid mutiple traversals
function getRoutes() {
  let _routes = clone(publicRoutes)

  allTransformations.forEach((callbackfn) => (_routes = callbackfn(_routes)))

  return _routes
}

function _parentMetaFieldToChildren(field) {
  return (_routes) => {
    _routes.forEach((r) => {
      if (r.children) {
        r.children.forEach((childRoute) => {
          childRoute.meta = {
            [field]: clone(r.meta[field]),
            ...childRoute.meta,
          }
        })
      }
    })

    return _routes
  }
}

function _childrenToMeta(_routes) {
  _routes.forEach((r) => {
    if (r.children) {
      const childrenRouteNames = r.children.map((r) => r.name)
      if (r.name) {
        childrenRouteNames.push(r.name)
      }

      r.children.forEach((childRoute) => {
        childRoute.meta = {
          children: childrenRouteNames,
          ...childRoute.meta,
        }
      })
      r.meta = { children: childrenRouteNames, ...r.meta }
    }
  })
  return _routes
}

routes = getRoutes()

export default routes
