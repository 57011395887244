export const GAME_BROWSE_ROUTE = 'game-browse-list'
export const GAME_ADD_ROUTE = 'game-new'
export const GAME_SUPPORT_ROUTE = 'game-contact'
export const GAME_ADMIN_ROUTE = 'game-admin'
export const GAME_ADMIN_SETTINGS_ROUTE = `${GAME_ADMIN_ROUTE}-settings`
export const GAME_ADMIN_TEAM_ROUTE = `${GAME_ADMIN_ROUTE}-team`
// export const GAME_ADMIN_MARKET_ROUTE = `${GAME_ADMIN_ROUTE}-market`
// export const GAME_ADMIN_PLAN_ROUTE = `${GAME_ADMIN_ROUTE}-plan`
export const GAME_ADMIN_REPORT_ROUTE = `${GAME_ADMIN_ROUTE}-reports`
export const GAME_ADMIN_STATS_ROUTE = `${GAME_ADMIN_ROUTE}-stats`
export const GAME_ADMIN_APIKEY_ROUTE = `${GAME_ADMIN_ROUTE}-apikey`

// MODS
export const MOD_BROWSE_ROUTE = 'mod-browse-list'
export const MOD_VIEW_ROUTE = 'mod-view'
export const MOD_ADD_ROUTE = 'mod-new'
export const MOD_ADMIN_ROUTE = 'mod-admin'
export const MOD_ADMIN_SETTINGS_ROUTE = `${MOD_ADMIN_ROUTE}-settings`
export const MOD_ADMIN_TEAM_ROUTE = `${MOD_ADMIN_ROUTE}-team`
export const MOD_ADMIN_STATUS_LOGS_ROUTE = `${MOD_ADMIN_ROUTE}-status-logs`
export const MOD_ADMIN_STATS_ROUTE = `${MOD_ADMIN_ROUTE}-stats`

// GUIDES
export const GUIDE_ADMIN_ROUTE = 'guide-admin'
export const GUIDE_BROWSE_ROUTE = 'guide-browse-list'
export const GUIDE_VIEW_ROUTE = 'guide-view-route'
export const GUIDE_ADD_ROUTE = 'guide-game-add'
export const GUIDE_ADMIN_EDIT_ROUTE = `${GUIDE_ADMIN_ROUTE}-edit`

// SUPERADMIN
export const ADMIN_ROUTE = 'admin'
export const ADMIN_STAFF_ROUTE = `${ADMIN_ROUTE}-staff`
export const ADMIN_STAFF_BROWSE_ROUTE = `${ADMIN_ROUTE}-staff-browse`
export const ADMIN_DASHBOARD_ROUTE = `${ADMIN_ROUTE}-dashboard`
export const ADMIN_USER_ROUTE = `${ADMIN_ROUTE}-users`
export const ADMIN_USER_MERGE_ROUTE = `${ADMIN_ROUTE}-users-merge`
export const ADMIN_KEYS = `${ADMIN_ROUTE}-keys`
export const ADMIN_GUIDE_BROWSE_ROUTE = `${ADMIN_ROUTE}-browse`
export const ADMIN_AGREEMENT_ROUTE = `${ADMIN_ROUTE}-agreement`
export const ADMIN_AGREEMENT_BROWSE_ROUTE = `${ADMIN_AGREEMENT_ROUTE}-browse`
export const ADMIN_AGREEMENT_ADD_ROUTE = `${ADMIN_AGREEMENT_ROUTE}-add`
export const ADMIN_AGREEMENT_EDIT_ROUTE = `${ADMIN_AGREEMENT_ROUTE}-edit`
export const ADMIN_AGREEMENT_TEMPLATE_ROUTE = `${ADMIN_AGREEMENT_ROUTE}-template`
export const ADMIN_AGREEMENT_TEMPLATE_ADD_ROUTE = `${ADMIN_AGREEMENT_ROUTE}-template-add`
export const ADMIN_AGREEMENT_TEMPLATE_EDIT_ROUTE = `${ADMIN_AGREEMENT_ROUTE}-template-edit`

// ABOUT
export const ABOUT_ROUTE = 'about'
export const CONTACT_ROUTE = 'contact'
export const CAREERS_ROUTE = 'careers'

// AGREEMENTS
export const TERMS_ROUTE = 'terms'
export const PRIVACY_ROUTE = 'privacy'
export const DEVELOPER_TERMS_ROUTE = 'developer'
export const PAYMENT_TERMS_ROUTE = 'payments'
export const API_TERMS_ROUTE = 'apiterms'
export const AUP_ROUTE = 'aup'

// STYLES
export const ICONS_ROUTE = 'icons'
export const STYLE_GUIDE_ROUTE = 'style-guide'

// ME
export const PROFILE_ROUTE = 'profile'
export const PROFILE_LIBRARY_ROUTE = `${PROFILE_ROUTE}-library`
export const PROFILE_COMMENTS_ROUTE = `${PROFILE_ROUTE}-comments`
export const PROFILE_FOLLOWERS_ROUTE = `${PROFILE_ROUTE}-followers`
export const PROFILE_ACCOUNT_ROUTE = `${PROFILE_ROUTE}-account`
export const PROFILE_ACCESS_ROUTE = `${PROFILE_ROUTE}-access`
export const PROFILE_PRIVACY_ROUTE = `${PROFILE_ROUTE}-privacy`

// USERS
export const USER_ROUTE = 'user'
export const USER_INFO_ROUTE = 'user-info'
export const USER_STATS_ROUTE = 'user-stats'
export const USER_GAME_ROUTE = 'user-game'
export const USER_GAME_INFO_ROUTE = 'user-game-info'

// MISC
export const LOGIN_ROUTE = 'login'
export const TEAM_INVITE_ROUTE = 'team-invite'
export const UNSUBSCRIBE_ROUTE = 'unsubscribe'
export const OAUTH_ROUTE = 'oauth'
export const HOME_ROUTE = 'home'
export const FOUROHFOUR_ROUTE = 'FourOhFour'
export const REPORT_ROUTE = 'report'
export const STATUS_ROUTE = 'status'
export const MEDIA_LIBRARY_ROUTE = 'media-library'
export const WIDGET_PREFIX_ROUTE = 'widget'

// DYNAMIC PARAMETERS
// TODO: add to route path
export const GAME_PARAM = ':game'
export const MOD_PARAM = ':mod'
export const GUIDE_PARAM = ':guide'
export const USER_PARAM = ':user'
export const UGC_PARAM = ':game:ugc'
