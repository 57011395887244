import { useSuggestions } from '@composables'
import * as seo from '@config/seo.js'
import {
  PROFILE_LIBRARY_ROUTE,
  MOD_BROWSE_ROUTE,
  FOUROHFOUR_ROUTE,
  MOD_VIEW_ROUTE,
  GAME_PARAM,
  MOD_PARAM,
  UGC_PARAM,
} from '@config/routeNames.js'

const { handleSuggestion } = useSuggestions()

export const publicRoutes = [
  {
    path: '/',
    name: MOD_BROWSE_ROUTE,
    component: () =>
      import(/* webpackChunkName: "embed" */ '@views/ModBrowse.vue'),
    meta: {
      browse: true,
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ game }) =>
            game.name ? `${game.name} ${game.ugc_name}` : '',
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'keywords' },
          content: seo.MOD_BROWSE_ROUTE_KEYWORDS_META,
          dynamicContent: ({ game }) => game.name,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          dynamicContent: ({ game }) => game.summary,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:image' },
          dynamicContent: ({ game }) => game.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:image' },
          dynamicContent: ({ game }) => game.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:title' },
          dynamicContent: ({ game }) => game.name,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:title' },
          dynamicContent: ({ game }) => game.name,
        },
      ],
      breadcrumb: {
        head: { label: GAME_PARAM, to: { name: MOD_BROWSE_ROUTE } },
        tail: { label: UGC_PARAM, to: { name: MOD_BROWSE_ROUTE } },
      },
      error: [
        {
          status: 404,
          errorRef: 14000,
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
        {
          status: 403,
          errorRef: '*',
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
      ],
    },
  },
  {
    path: '/m/:mod',
    name: MOD_VIEW_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ mod }) => mod.name,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'keywords' },
          content: seo.MOD_VIEW_ROUTE_KEYWORDS_META,
          dynamicContent: ({ game, mod }) =>
            [game.name, mod.name].filter((n) => n).join(', '),
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          dynamicContent: ({ mod }) => mod.summary,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:image' },
          dynamicContent: ({ mod }) => mod.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:image' },
          dynamicContent: ({ mod }) => mod.card_image,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'og:title' },
          dynamicContent: ({ mod }) => mod.name,
        },
        {
          tag: 'meta',
          name: { attribute: 'property', value: 'twitter:title' },
          dynamicContent: ({ mod }) => mod.name,
        },
      ],
      error: [
        {
          status: '*',
          errorRef: '*',
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
      ],

      breadcrumb: {
        head: { label: GAME_PARAM, to: { name: MOD_BROWSE_ROUTE } },
        mods: { label: UGC_PARAM, to: { name: MOD_BROWSE_ROUTE } },
        tail: {
          label: MOD_PARAM,
          to: { name: MOD_VIEW_ROUTE },
        },
      },
    },
    component: () =>
      import(/* webpackChunkName: "embed" */ '@views/ModView.vue'),
  },
  {
    path: '/collection',
    name: PROFILE_LIBRARY_ROUTE,
    meta: {
      seo: [{ tag: 'title', content: 'Collection' }],
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "embed" */ '@views/Collection.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: FOUROHFOUR_ROUTE,
    component: () => import(/* webpackChunkName: "404" */ '@views/404.vue'),
  },
]
